import axios from 'axios'
import { validateToken } from '../actions/AuthActions'

export function setAuthFromLocalStorage() {
  const authKeys = ['access-token', 'client', 'uid']
  authKeys.forEach((authDetail) => {
    if (!localStorage.getItem(authDetail)) return;
    axios.defaults.headers.common[authDetail] = localStorage.getItem(authDetail)
  })
}

export function setCurrentUser(headers) {
  localStorage.setItem('access-token', headers['access-token'])
  localStorage.setItem('client', headers['client'])
  localStorage.setItem('uid', headers['uid'])
  setAuthFromLocalStorage()
}

export function setAuthInterceptor() {
  axios.interceptors.response.use((response) => {

    if (response.headers['access-token']) {
      setCurrentUser(response.headers)
    }

    return response
  }, function (error) {
    if (error.response) {
      console.log("Switchingstatus", error.response.status)
      console.log("error.response", error.response)
      switch(error.response.status) {
        case 401:
          clearCurrentUser()
          break
        case 422:
          console.log("Record Save Error", error.response)
          break
        case 500:
          console.log("Server Error", error.response)
          console.log("This action is unavailable. Please try again soon.")
          validateToken()
          break
        default:
          console.log("Unknown Request Error", error.response)
      }
    } else {
      console.log("Network error detected")
      alert("Network error. Please check your connection and try again.")
    }

    return Promise.reject(error);
  })
}

export function clearCurrentUser() {
  localStorage.removeItem('access-token')
  localStorage.removeItem('client')
  localStorage.removeItem('uid')
  setAuthFromLocalStorage()
}
