const initialState = {
  user: JSON.parse(localStorage.getItem('redux_sync_AuthUser')),
  loading: false,
  isSignedIn: !!localStorage.getItem('access-token'),
  validating: false,
  validated: false,
  errors: null,
  resetPasswordSent: false,
  resetPasswordLoading: false,
}

let user

export default function (state = initialState, action) {
  switch (action.type) {

    case 'USER_SIGN_IN_PENDING':
      return {
        ...state,
        errors: null,
        loading: true,
      }

    case 'USER_SIGN_IN_REJECTED':
      return {
        ...state,
        errors: action.payload.response ? action.payload.response.data.errors : action.payload.message,
        loading: false,
      }

    case 'USER_SIGN_IN_FULFILLED':
      user = action.payload.data.data
      localStorage.setItem('redux_sync_AuthUser',JSON.stringify(user))
      return {
        ...state,
        user: user,
        errors: null,
        loading: false,
        isSignedIn: true,
      }

    case 'USER_RESET_PASSWORD_PENDING':
      return {
        ...state,
        resetPasswordLoading: true,
      }

    case 'USER_RESET_PASSWORD_FULFILLED':
      return {
        ...state,
        resetPasswordSent: true,
      }

    case 'USER_RESET_PASSWORD_REJECTED':
      return {
        ...state,
        resetPasswordLoading: false,
      }

    case 'USER_NEW_PASSWORD_PENDING':
      return {
        ...state,
        resetPasswordLoading: true,
      }

    case 'USER_NEW_PASSWORD_FULFILLED':
      return {
        ...state,
        resetPasswordLoading: false,
      }

    case 'USER_NEW_PASSWORD_REJECTED':
      return {
        ...state,
        resetPasswordLoading: false,
      }

    case 'USER_RESET_PASSWORD_DISMISS':
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordSent: false,
      }

    case 'SIGN_OUT':
      localStorage.removeItem('redux_sync_AuthUser')
      return {
        ...state,
        user: null,
        isSignedIn: false,
        loading: true,
      }

    case 'SIGN_OUT_COMPLETE':
      return {
        ...state,
      }

    case  'USER_SIGN_OUT_PENDING':
      return {
        ...initialState
      }

    case 'USER_SIGN_OUT_REJECTED':
    case 'USER_SIGN_OUT_FULFILLED':
      localStorage.removeItem('redux_sync_AuthUser')
      return {
        ...initialState
      }

    case 'VALIDATE_TOKEN_PENDING':
      return {
        ...state,
        validating: true
      }

    case 'VALIDATE_TOKEN_REJECTED':
      return {
        ...state,
        validating: false,
        validated: false,
        isSignedIn: false,
        user: false,
      }

    case 'VALIDATE_TOKEN_FULFILLED':
      return {
        ...state,
        validating: false,
        validated: true,
        isSignedIn: true,
      }

    default:
      return state
  }
}
