import React from 'react'
import { connect } from 'react-redux'
import { fetchSites } from '../actions/SiteActions'
import { fetchInfoAlerts } from '../actions/InfoAlertActions'

class ScheduledDataUpdater extends React.Component {

  updateData = () => {
    const { dispatch } = this.props
    dispatch(fetchSites())
    dispatch(fetchInfoAlerts())
  }

  componentDidMount = () => {

    setInterval(() => {
      this.updateData.call(this)  
    },60000)

	  document.addEventListener("visibilitychange", () => {
	    if (document.visibilityState === 'visible') {
	      this.updateData.call(this)
	    }
	  })

    this.updateData.call(this)

  }

  render = () => null

}

export default connect()(ScheduledDataUpdater)
