import styled, { css, keyframes } from 'styled-components'
import styledTheme from '../../styles/theme'
import Button from '@material-ui/core/Button'
import { ContainerHeading, ContainerHeadingTextDiv, ContainerHeadingActionIconsDiv, ContainerHeadingActionIcon } from '../../styles/styled-elements'

export { styledTheme, ContainerHeading, ContainerHeadingTextDiv, ContainerHeadingActionIconsDiv, ContainerHeadingActionIcon }

export const Form = styled.form`
  width: 100%;
  padding: 0 40px;
  @media (min-width: 768px) {
    width: 400px;
  }
`
export const FieldsWrapper = styled.div`
  margin: 20px 0;
`
export const FieldDiv = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    margin-bottom: 20px;
  }
`
export const SubmitWrapper = styled.div`
  text-align: center;
  padding: 20px 0;
  @media (min-width: 768px) {
    margin-bottom: 20px;
  }
`
export const DataViewModalContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 10px;
  left: 10px;
  right: 10px;
  top: 10px;
  @media (max-width: ${styledTheme.breakpoints.sm}px), (max-height: ${styledTheme.breakpoints.sm}px) {
    bottom: 0px;
    left: 0px;
    right: 0px;
    top: 0px;
  };
  border: 1px solid ${styledTheme.border_grey};
  background-color: #FFF;
  z-index: 30;
`
export const GraphContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  max-height: calc(100% - 40px);
`
export const GraphContainer = styled.div`
  display: inline-flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100%;
`
export const DataTableModalContainer = styled(DataViewModalContainer)`
`
export const TableContainer = styled.div`
  display: inline-flex;
  flex: 1;
  width: 100%;
  overflow: scroll;
`
export const DashboardContainer = styled.div`
  display: block;
  flex-direction: column;
  width: 100%;
  overflow: scroll;
  background-color: #EDEDED;
`
export const CellVerticalPadding = css`
  padding-top: 10px;
  padding-bottom: 10px;
`
export const Table = styled.table`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  border-collapse: collapse;
  margin-bottom: 10px;
  width: 100%;
`
export const THead = styled.thead`
  th:first-child {
    padding-right: 40px;
    max-width: unset !important;
  }
  th:last-child {
    width: 100%;
  }
`
export const TBody = styled.tbody`
  td:first-child {
    padding-right: 40px;
    max-width: unset !important;
  }
  td:last-child {
    width: 100%;
  }
`
export const HeaderRow = styled.tr`
`
export const HeaderCell = styled.th`
  position: sticky;
  top: 0;
  background-color: #FFF;
  text-align: ${({first}) => first ? 'left' : 'center' };
  padding-left: ${({first}) => first ? '0' : '25px' };
  padding-top: 10px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
  min-width: 70px;
  max-width: 70px;
  white-space: wrap;
  text-overflow: ellipsis;
`
export const UnitsRow = styled.tr`
`
export const UnitCell = styled.td`
  text-align: ${({first}) => first ? 'left' : 'right' };
  padding-left: ${({first}) => first ? '0' : '0' };
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  min-width: 70px;
  max-width: 70px;
  white-space: nowrap;
  text-overflow: ellipsis;
`
export const DataRow = styled.tr`
  ${CellVerticalPadding}
  &:nth-child(even) {
    background-color: #EDEDED;
  }
`
export const DataCell = styled.td`
  ${CellVerticalPadding}
  text-align: right;
  border-top: 1px solid #CCC;
  padding-left: 10px;
  padding-right: 10px;
  min-width: 70px;
  max-width: 70px;
  white-space: nowrap;
  text-overflow: ellipsis;
`
export const DataTableFooter = styled.div`
  border-top: 1px solid ${styledTheme.border_grey};
  background-color: white;
  display: inline-flex;
  height: 40px;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 10px;
`
export const DownloadButton = styled(Button)`
  border-radius: 2px !important;
  min-height: unset !important;
  font-size: 12px !important;
  padding: 4px 8px !important;
`
export const DashboardToolbarContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  border: 1px solid black;
`
export const DashboardToolbarExpandLinkCell = styled.div`
  display: inline-flex;
  align-items: flex-end;
`
export const DashboardToolbarExpandLink = styled.span`
  padding: 0.4rem 0.6rem;
  background-color: ${styledTheme.colors.button_background};
  color: #FFF;
  border: 1px solid #333;
  text-decoration: none;
  cursor: pointer;
  font-size: 0.8rem;
`
export const DashboardTopRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
export const DashboardMapContainer = styled.div`
  display: inline-flex;
  flex: 1;
  @media (min-width: 0px) {
    width: 100%;
  };
  @media (min-width: ${styledTheme.breakpoints.md}px) {
    width: 50%;
  };
`
export const MapComponentInnerContainer = styled.div`
  width: 100%;
  height: 220px;
  border: 1px solid black;
`
export const DashboardMapMarkerDiv = styled.div`
  display: block;
  width: fit-content;
  height: fit-content;
  transform: translateX(-50%) translateY(-100%);
`
export const KeyIndicatorStatusPanel = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  height: 220px;
  ${({height}) => height ? `height: ${height}px;` : ''}
  ${({backgroundColor}) => backgroundColor ? `background-color: ${backgroundColor};` : ''}
  ${({fontColor}) => fontColor ? `color: ${fontColor};` : ''}
  @media (min-width: 0px) {
    width: 100%;
    height: 200px;
  };
  @media (min-width: ${styledTheme.breakpoints.md}px) {
    width: 25%;
    height: 220px;
  };
`
export const KeyIndicatorStatusPanelHeading = styled.div`
  display: flex;
  flex-direction: vertical;
  align-items: center;
  font-size: 0.8rem;
  max-width: 10rem;
  height: 2.5rem;
  text-align: center;
  ${({fontColor}) => fontColor ? `color: ${fontColor};` : ''}
`
export const KeyIndicatorStatusPanelValue = styled.div`
  font-size: 5rem;
  ${({fontColor}) => fontColor ? `color: ${fontColor};` : ''}
  ${({fontWeight}) => fontWeight ? `font-weight: ${fontWeight};` : ''}
`
export const KeyIndicatorStatusPanelValueUnit = styled.sup`
  ${({fontColor}) => fontColor ? `color: ${fontColor};` : ''}
  font-size: 1rem;
  vertical-align: top;
  position: relative;
  top: 1rem;
`
export const KeyIndicatorStatusPanelStatusLabel = styled.div`
  ${({fontColor}) => fontColor ? `color: ${fontColor};` : ''}
`
export const MetricsPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #444444;
  border: 1px solid black;
  background-color: #FFF;
  min-height: 200px;
  padding: 10px;
`
export const MetricsPanelHeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const MetricsPanelHeading = styled.h2`
  display: inline-flex;
  width: 100%;
  font-size: 1.6rem;
  font-weight: bold;
  padding: 4px 8px 0px 8px;
  margin-block-start: 0.8rem;
  margin-block-end: 0.2rem;
`
export const MetricsPanelLastUpdated = styled.div`
  display: inline-flex;
  width: 100%;
  padding: 2px 8px 8px 8px;
  font-size: 0.9rem;
  margin-bottom: 10px;
`
export const MetricsPanelRow = styled.div`
  display: inline-flex;
  width: 100%;
  padding: 0.6rem;
  border-bottom: 1px solid #ADC0BE;
  &:nth-child(odd) {
    background-color: #EDEDED;
  }
  &:last-child {
    border: none;
  }
  font-size: 0.8rem;
  flex-direction: column;
  @media (min-width: 768px) {
    font-size: 1rem;
    flex-direction: row;
  }
`
export const MetricsPanelDiv = styled.div`
  border: 1px solid #ADC0BE;
`
export const MetricsPanelLabelCell = styled.div`
  display: inline-flex;
  flex: 1;
  padding-right: 20px;
  min-width: 300px;
  @media (min-width: 768px) {
    align-items: flex-start;
 }
`
export const MetricsPanelValueCell = styled.div`
  display: inline-flex;
  flex: 1;
  font-weight: 700;
  padding-right: 20px;
  margin: 10px 0;
  font-size: 2em;
  min-width: 150px;
  @media (min-width: 768px) {
    font-size: 1rem;
    margin: 0;
    align-items: flex-start;
  }
`
export const MetricsPanelStatusCell = styled.div`
  display: inline-flex;
  flex: 1;
  padding-right: 20px;
  align-items: center;
  min-width: 200px;
  @media (min-width: 768px) {
    align-items: flex-start;
  }
`
export const MetricsPanelFillCell = styled.div`
  display: inline-flex;
  padding-right: 20px;
`
export const MetricIndicatorBox = styled.div`
  display: inline-flex;
  background-color: ${({backgroundColor}) => backgroundColor};
  border: 1px solid black;
  margin-right: 1rem;
  width: 40px;
  height: 18px;
`
export const DashboardGraphContainer = styled.div`
  padding: 6px;
  width: calc(100% - 20px);
  height: 400px;
  margin: 10px;
  margin-top: 10px;
  border: 1px solid #ADC0BE;
  border-radius: 3px;
  display: flex;
  background-color: #FFF;
  flex-direction: column;
`
export const DashboardGraphHeaderRow = styled.div`
  display: flex;
`
export const DashboardGraphHeadingCell = styled.div`
  display: inline-flex;
  align-items: flex-end;
`
export const DashboardGraphHeading = styled.h3`
  margin: 0.4rem 0 0 0.4rem;
  cursor: pointer;
  font-size: 1rem;
`
export const DashboardGraphHeadingExpandLinkCell = styled.div`
  display: inline-flex;
  padding-left: 10px;
  align-items: flex-end;
`
export const DashboardGraphHeadingExpandLink = styled.span`
  padding: 2px 4px;
  background-color: ${styledTheme.colors.button_background};
  color: #FFF;
  text-decoration: none;
  cursor: pointer;
  font-size: 0.6rem;
`
export const DashboardGraphInnerContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex: 1;
`
function LdsDualRingAnimation() {
  return keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  `;
}
export const LdsDualRingSpinnerDefault = styled.div`
  display: inline-block;
  width: 64px;
  height: 64px;
  &:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid ${styledTheme.colors.loading_spinner_color_grey};
    border-color: ${styledTheme.colors.loading_spinner_color_grey} transparent ${styledTheme.colors.loading_spinner_color_grey} transparent;
    animation: ${LdsDualRingAnimation()} 1s linear infinite;
  }
`
export const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 40;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({show}) => show ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
`

export const NoDataDiv = styled.div`
  padding: 10px;
`
