import axios from 'axios'

export function fetchAwsNetwork(hostname) {
  return (dispatch, getState) => {
    dispatch({
      type: 'AWS_NETWORK_FETCH',
      payload: axios(`/aws_networks/${hostname}`)
    })
  }
}
