import React from "react"
import { FormControl, FormLabel, FormControlLabel, TextField,  } from "@material-ui/core"
import { DateTime } from "luxon"
import DateFnsUtils from "@date-io/luxon"
import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker } from "@material-ui/pickers"
import Grid from "@material-ui/core/Grid"
import { ModalScreenOverlay, GrowingSeasonSettingsPanelContainer, GrowingSeasonSettingsPanelCloseIcon } from "./styled-elements"

const styles = {
    buttonsContainer: {
      display: 'inline-flex',
      justifyContent: 'flex-end',
      paddingTop: '10px',
    },
    button: {
      border: '2px solid #2F6BE1',
      borderRadius: '3px',
      padding: '4px 8px',
      fontSize: '12px',
      color: '#2F6BE1',
      marginLeft: '10px',
      cursor: 'pointer',
    },
  }

export default class GrowingSeasonSettingsPanel extends React.Component {

  constructor(props) {
    super(props)
  }

  callCloseActionIfClickOutsidePopup = (event,cancelHandler) => {
    if (event.target === event.currentTarget) cancelHandler.call()
  }

  formatDateTime = (dateTimeVal) => {
    if (typeof dateTimeVal==='undefined' || dateTimeVal==='') {
      return null
    }
    return dateTimeVal
  }

  renderBody = (props,state) => {

    const { growingSeasonName, sowingDate, emergenceDate, floralInitiationDate, harvestDate, nameChangeHandler, dateChangeHandler, submitHandler, cancelHandler } = props

    return <ModalScreenOverlay>
      <GrowingSeasonSettingsPanelContainer>
        <GrowingSeasonSettingsPanelCloseIcon onClick={cancelHandler} />
        <FormControl component="fieldset">
          <FormLabel component="legend">Growing Season Settings</FormLabel>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justifyContent="space-around" justifyContent="flex-start">
              <TextField
                margin="normal"
                label="Growing Season Name"
                inputProps={{ style: { width: 226 }}}
                name={'name'}
                value={growingSeasonName}
                onChange={nameChangeHandler}
              />
            </Grid>
            <Grid container justifyContent="space-around">
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Sowing Date"
                name={'sowingDate'}
                value={this.formatDateTime(sowingDate)}
                onChange={(date) => dateChangeHandler.call(this,'sowingDate',date)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
            <Grid container justifyContent="space-around">
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Emergence Date"
                value={this.formatDateTime(emergenceDate)}
                onChange={(date) => dateChangeHandler.call(this,'emergenceDate',date)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
            <Grid container justifyContent="space-around">
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Floral Initation Date"
                value={this.formatDateTime(floralInitiationDate)}
                onChange={(date) => dateChangeHandler.call(this,'floralInitiationDate',date)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
            <Grid container justifyContent="space-around">
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Harvest Date"
                value={this.formatDateTime(harvestDate)}
                onChange={(date) => dateChangeHandler.call(this,'harvestDate',date)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
          <div style={styles.buttonsContainer}>
            <div style={styles.button} onClick={cancelHandler} key={1}>
              Cancel
            </div>
            <div style={styles.button} onClick={submitHandler} key={2}>
              Save
            </div>
          </div>

        </FormControl>
      </GrowingSeasonSettingsPanelContainer>
    </ModalScreenOverlay>
  }

  render() {
    return this.renderBody(this.props,this.state)
  }
}
