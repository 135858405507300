import React from 'react'
import GfdiLegend from './legends/GfdiLegend'
import VtdLegend from './legends/VtdLegend'
import TemperatureLegend from './legends/TemperatureLegend'
import RelativeHumidityLegend from './legends/RelativeHumidityLegend'
import DewPointLegend from './legends/DewPointLegend'
import DeltaTLegend from './legends/DeltaTLegend'
import WindSpeedLegend from './legends/WindSpeedLegend'
import DailyRainfallLegend from './legends/DailyRainfallLegend'

import { LegendContainer } from './styled-elements'

export default class Legend extends React.Component {

  generateInnerConent = (metricKey, metricDisplayStatuses, unitsHash) => {
    if (metricKey==='gfdi') return <GfdiLegend
      metricDisplayStatuses={metricDisplayStatuses}
    />
    if (metricKey==='ghcop') return <GfdiLegend
      metricDisplayStatuses={metricDisplayStatuses}
    />
    if (metricKey==='vtd') return <VtdLegend
      metricDisplayStatuses={metricDisplayStatuses}
    />
    if (metricKey==='temperature') return <TemperatureLegend
      metricDisplayStatuses={metricDisplayStatuses}
      unit={unitsHash['degrees_c']}
    />
    if (metricKey==='relative_humidity') return <RelativeHumidityLegend
      metricDisplayStatuses={metricDisplayStatuses}
      unit={unitsHash['percent']}
    />
    if (metricKey==='delta_t') return <DeltaTLegend
      metricDisplayStatuses={metricDisplayStatuses}
      unit={unitsHash['degrees_c']}
    />
    if (['wind_speed_2m','wind_speed_10m'].includes(metricKey)) return <WindSpeedLegend
      metricDisplayStatuses={metricDisplayStatuses}
      unit={unitsHash['km_h']}
    />
    if (metricKey==='dew_point') return <DewPointLegend
      metricDisplayStatuses={metricDisplayStatuses}
      unit={unitsHash['degrees_c']}
    />
    if (['current_day_rainfall','previous_day_rainfall'].includes(metricKey)) return <DailyRainfallLegend
      metricDisplayStatuses={metricDisplayStatuses}
      unit={unitsHash['millimetres']}
    />
    return null
  }

  renderBody = ({ metricKey, metricDisplayStatuses, unitsHash }) => {
    const innerContent = this.generateInnerConent(metricKey, metricDisplayStatuses, unitsHash)
    return innerContent && <LegendContainer>
      {innerContent}
    </LegendContainer>
  }

  render() {
    return this.renderBody(this.props)
  }
}
