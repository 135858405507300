import React from 'react'

const OctagonMarker = () => (<svg viewBox="0 0 72 72" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
  <defs>
    <polygon id="path-1" points="19.898 67.936 48.037 67.936 67.935 48.039 67.935 19.899 48.037 0 19.898 0 0 19.898 0 48.038"></polygon>
  </defs>
  <g id="Page-1" fill="none" fillRule="evenodd">
    <g id="octagon-outline-shape" transform="translate(2.000000, 2.000000)">
      <g id="path">
        <use xlinkHref="#path-1"></use>
        <path d="M19.4837864,68.936 L-1,48.4522136 L-1,19.4837864 L19.4837864,-1 L48.4512283,-1 L68.935,19.4848012 L68.935,48.4532283 L48.4511988,68.936 L19.4837864,68.936 Z"></path>
      </g>
    </g>
  </g>
</svg>)

export default OctagonMarker
