import axios from 'axios'

export function fetchGrowingSeasons() {
  return (dispatch, getState) => {
    dispatch({
      type: 'GROWING_SEASONS_FETCH',
      payload: axios(`/members/growing_seasons`)
    })
  }
}

export function updateGrowingSeason(id,updateParams) {
  return (dispatch, getState) => {
    dispatch({
      type: 'GROWING_SEASON_UPDATE',
      payload: axios.put(`/members/growing_seasons/${id}`,updateParams)
    })
  }
}

