import styled, { css, keyframes } from 'styled-components'
import { Page, Row, Column } from 'hedron'
import styledTheme from './theme'

export const ScreenWidthCss = css`
  @media (min-width: 0px) {
    width: 100%;
  };
  @media (min-width: ${styledTheme.breakpoints.lg+500}px) {
    width: ${styledTheme.breakpoints.lg+500}px;
  };
`
export const ScreenWidthContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: start;
	margin: 0 auto;
	overflow: hidden;
  ${ScreenWidthCss}
`
export const GridSection = styled(Page)`
  width: 100%;
  ${({ xsMarginStr }) => typeof xsMarginStr==='string' ? `@media (min-width: 0px) { margin: ${xsMarginStr};}` : null }
  ${({ mdMarginStr }) => typeof mdMarginStr==='string' ? `@media (min-width: ${styledTheme.breakpoints.md}px) { margin: ${mdMarginStr};}` : null }
`
export { Column, Row }
export const ReverseRow = styled(Row)`
  flex-wrap: wrap-reverse;
`
export const StandardTextStyle = css`
  font-size: ${styledTheme.sizes.paragraph};
  font-family: ${styledTheme.fonts.paragraph};
  color: #000;
  text-align: left;
  line-height: 30px;
  font-weight: 300;
`
export const Button = styled.button`
  ${StandardTextStyle}
  border: 1px solid black;
  background: none;
  padding: 6px 12px;
  font-size: 14px;
  cursor: pointer;
`
export const ActionLink = styled.span`
  color: ${styledTheme.colors.action_link};
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`
export const ActionIcon = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: 20px;
  &:hover {
    cursor: pointer;
  }
`
export const H1 = styled.h1`
  margin-top: 1rem;
  font-size: 1.6rem;
  margin-bottom: 1rem;
`
export const ContainerHeading = styled.div`
  background-color: ${styledTheme.colors.container_header_background};
  color: #FFF;
  font-weight: 500;
  font-size: 1rem;
  min-height: 40px;
  display: flex;
  align-items: center;
  @media (max-width: ${styledTheme.breakpoints.md-1}px) {
    font-size: 1.125rem;
  };
`
export const ContainerHeadingTextDiv = styled.div`
  display: inline-flex;
  align-items: center;
  margin: 10px;
  width: fit-content;
  align-items: center;
  justify-content: flex-start;
`
export const ContainerHeadingActionIconsDiv = styled(ContainerHeadingTextDiv)`
  flex: 1;
  justify-content: flex-end;
`
export const ContainerHeadingActionIcon = styled(ActionIcon)`
  svg {
    fill: #FFF;
    height: 30px;
    width: 30px;
  }
  @media (min-width: ${styledTheme.breakpoints.md}px) {
    height: 26px;
    width: 26px;
  };
`
export const GridContainer = styled.div`
  background-color: #FFF;
  border: 1px solid #979797;
`
export const InnerContentContainer = styled.div`
  background-color: #FFF;
  border: 1px solid #979797;
  padding: 20px;
  p {
    max-width: 600px;
  }
`
function LdsDualRingAnimation() {
  return keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  `;
}
export const LdsDualRingSpinnerDefault = styled.div`
  display: inline-block;
  width: 64px;
  height: 64px;
  &:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid ${styledTheme.colors.loading_spinner_color_grey};
    border-color: ${styledTheme.colors.loading_spinner_color_grey} transparent ${styledTheme.colors.loading_spinner_color_grey} transparent;
    animation: ${LdsDualRingAnimation()} 1s linear infinite;
  }
`
export const LoadingOverlay = styled.div`
  position: fixed;
  z-index: 50;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({show}) => show ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
`
