import axios from 'axios'

export function fetchSites() {
  return (dispatch, getState) => {
    dispatch({
      type: 'SITES_FETCH',
      payload: axios(`/sites`)
    })
  }
}

export function fetchSiteSettings() {
  return (dispatch, getState) => {
    dispatch({
      type: 'SITE_SETTINGS_FETCH',
      payload: axios(`/sites/settings`)
    })
  }
}
