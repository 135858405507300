import axios from 'axios'
import { push } from 'connected-react-router'

import { clearCurrentUser } from '../utils/authUtils'

export function validateToken() {
  return (dispatch, getState) => dispatch({
    type: 'VALIDATE_TOKEN',
    payload: axios('/auth/validate_token'),
  }).catch((error) => {
    clearCurrentUser()
    return Promise.reject(error)
  })
}

export function signIn(credentials) {
  return (dispatch, getState) => {
    dispatch({
      type: 'USER_SIGN_IN',
      payload: axios.post('/auth/sign_in', credentials)
    })
    .then((response) => {
      const user = response.action.payload.data.data
      dispatch(push('/'))
    })
    .catch((error) => {
      clearCurrentUser()
      return Promise.reject(error)
    })
  }
}

export function resetPassword(email) {
  return (dispatch) => {
    dispatch({
      type: 'USER_RESET_PASSWORD',
      payload: axios.post('/auth/password', { email }),
    })
  }
}

export function signOut() {
  return (dispatch) => {
    const logout = () => {
      clearCurrentUser()
      dispatch(push('/login'))
    }
    dispatch({
      type: 'USER_SIGN_OUT',
      payload: axios.delete('/auth/sign_out')
    })
    .then(() => {
      logout()
    })
    .catch((error) => {
      logout()
    })
  }
}


export function setRedirectAfterLogin(path) {
  return {
    type: 'SET_REDIRECT_AFTER_LOGIN',
    payload: path,
  }
}

export function resetRedirectAfterLogin() {
  return {
    type: 'RESET_REDIRECT_AFTER_LOGIN',
  }
}
