import React from 'react'
import GoogleMapReact from 'google-map-react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import Moment from 'moment-timezone'
import ReactGA from 'react-ga'
import { isMobile } from "react-device-detect"
import { DataViewModalContainer, ContainerHeadingActionIcon,
  DashboardContainer, DashboardToolbarContainer, DashboardToolbarExpandLinkCell, DashboardToolbarExpandLink,
  DashboardTopRow, DashboardMapContainer, MapComponentInnerContainer, DashboardMapMarkerDiv,
  KeyIndicatorStatusPanel, KeyIndicatorStatusPanelHeading, KeyIndicatorStatusPanelValue,
  KeyIndicatorStatusPanelValueUnit, KeyIndicatorStatusPanelStatusLabel, MetricsPanelContainer,
  MetricsPanelHeadingContainer, MetricsPanelHeading, MetricsPanelLastUpdated, MetricsPanelDiv,
  MetricsPanelRow, MetricsPanelLabelCell, MetricsPanelValueCell, MetricsPanelStatusCell, MetricsPanelFillCell,
  MetricIndicatorBox, DashboardGraphContainer, DashboardGraphHeaderRow, DashboardGraphHeadingCell,
  DashboardGraphHeading, DashboardGraphHeadingExpandLinkCell, DashboardGraphHeadingExpandLink,
  DashboardGraphInnerContainer } from './styled-elements'
import CloseCircle from '../icons/CloseCircle'
import LoadingIndicator from './LoadingIndicator'
import MapMarker from '../icons/MapMarker'
import ContainerHeading from '../layout/ContainerHeading'
import HelmetTitle from '../../components/layout/HelmetTitle'
import styledTheme from '../../styles/theme'
import Environment from '../../utils/environment'

const Marker = () => <DashboardMapMarkerDiv><MapMarker /></DashboardMapMarkerDiv>

const MapComponent = ({ data, location }) => {
  return <MapComponentInnerContainer>
    <GoogleMapReact
      bootstrapURLKeys={{ key: Environment.GOOGLE_MAP_API_KEY }}
      center={location}
      zoom={10}
      options={{
        fullscreenControl: true,
        zoomControl: !isMobile,
        gestureHandling: "greedy",
      }}
    >
      {<Marker lat={location.lat} lng={location.lng} />}
    </GoogleMapReact>
  </MapComponentInnerContainer>}

const KeyIndicatorStatus = ({heading,data}) => {
  return <KeyIndicatorStatusPanel
    backgroundColor={data.styles.background_color}
    fontColor={data.styles.font_color}
  >
    <KeyIndicatorStatusPanelHeading>
      {heading}
    </KeyIndicatorStatusPanelHeading>
    <KeyIndicatorStatusPanelValue fontWeight={data.styles.font_weight}>
      {data.value}<KeyIndicatorStatusPanelValueUnit>{data.unit}</KeyIndicatorStatusPanelValueUnit>
    </KeyIndicatorStatusPanelValue>
    <KeyIndicatorStatusPanelStatusLabel>
      {data.status_label}
    </KeyIndicatorStatusPanelStatusLabel>
  </KeyIndicatorStatusPanel>
}

const formatDateUpdated = (dateUpdatedStr,timezone) => {

  const unknownDateStr = "Updated date unknown"
  const aYearAgo = Moment(new Date()).subtract('1','years').format('x')

  const dateUpdatedMs = Date.parse(dateUpdatedStr)
  console.log("XXX dateUpdatedMs",dateUpdatedMs)

  if (typeof dateUpdatedMs!=='number') return unknownDateStr
  if (dateUpdatedMs===0) return unknownDateStr
  if (dateUpdatedMs < aYearAgo) return unknownDateStr

  const dateUpdatedMoment = Moment.tz(dateUpdatedMs,timezone)
  const nowMoment = Moment.tz(new Date(),timezone)
  const diffDuration  = Moment.duration(nowMoment.diff(dateUpdatedMoment))
  const diffDays = diffDuration ? diffDuration.asDays() : 0
  const diffHours = diffDuration ? diffDuration.asHours() : 0
  const diffMins = diffDuration ? diffDuration.asMinutes() : 0

  let updatedAtRelativeStr = ''

  if (diffHours < 2) {
    if (diffMins < 1) {
      updatedAtRelativeStr = `less than a minute ago`
    } else if (diffMins < 2) {
      updatedAtRelativeStr = `a minute ago`
    } else {
      updatedAtRelativeStr = `${parseInt(diffMins)} minutes ago`
    }
  } else if (diffHours < 48) {
    updatedAtRelativeStr = `${parseInt(diffHours)} hour${diffHours > 1 ? 's' : ''} ago`
  } else {
    updatedAtRelativeStr = `${parseInt(diffDays)} day${diffDays > 1 ? 's' : ''} ago`
  }

  let updatedAtTimeStr = dateUpdatedMoment.format("HH:mm")
  if (nowMoment.format("DD MMM YYYY")!==dateUpdatedMoment.format("DD MMM YYYY")) {
    updatedAtTimeStr += ` on ${dateUpdatedMoment.format("DD MMM")}`
    if (nowMoment.format("YYYY")!==dateUpdatedMoment.format("YYYY")) {
      updatedAtTimeStr += ` on ${dateUpdatedMoment.format("YYYY")}`
    }
  }

  if (diffHours < 240) {
    updatedAtTimeStr += ` (${updatedAtRelativeStr})`
  }

  return `Updated at ${updatedAtTimeStr}`

}

const Toolbar = ({dashboardToolbarExpandLinkClickHandler,siteSlug}) => <DashboardToolbarContainer>
  <DashboardToolbarExpandLinkCell><DashboardToolbarExpandLink onClick={dashboardToolbarExpandLinkClickHandler.bind(this)}>View Site Data Views List</DashboardToolbarExpandLink></DashboardToolbarExpandLinkCell>
</DashboardToolbarContainer>

const MetricsPanel = ({site,statusArray,awsType}) => {
  if (!statusArray) return null
  return <MetricsPanelContainer>
    <MetricsPanelHeadingContainer>
      <MetricsPanelHeading>Summary – {site.name}</MetricsPanelHeading>
      <MetricsPanelLastUpdated>{formatDateUpdated(site.current_data_last_updated,site.timezone_name)}</MetricsPanelLastUpdated>
    </MetricsPanelHeadingContainer>
    <MetricsPanelDiv>
    {statusArray.sort(({sort_order}) => sort_order).map(({label, value_str, status_label, styles},index) => {
      if ((awsType==='bom') && (value_str==='B')) return null
      if ((awsType==='hilltop') && (value_str==='H')) return null
      return <MetricsPanelRow key={index}>
        <MetricsPanelLabelCell>
          {label}
        </MetricsPanelLabelCell>
        <MetricsPanelValueCell>
          {value_str}
        </MetricsPanelValueCell>
        <MetricsPanelStatusCell>
          <MetricIndicatorBox backgroundColor={styles.background_color} /> {status_label}
        </MetricsPanelStatusCell>
        <MetricsPanelFillCell />
      </MetricsPanelRow>
    })}
    </MetricsPanelDiv>

  </MetricsPanelContainer>
}

const Graph = React.forwardRef(({ title, options, state, children, graphExpandButtonClickHandler, siteSlug, dataViewSlug }, ref) => {
  const dataViewName = title
  return <DashboardGraphContainer>
    <DashboardGraphHeaderRow>
      <DashboardGraphHeadingCell><DashboardGraphHeading onClick={graphExpandButtonClickHandler.bind(this,dataViewName,dataViewSlug)}>Graph - {title}</DashboardGraphHeading></DashboardGraphHeadingCell>
      <DashboardGraphHeadingExpandLinkCell><DashboardGraphHeadingExpandLink onClick={graphExpandButtonClickHandler.bind(this,dataViewName,dataViewSlug)}>Expand</DashboardGraphHeadingExpandLink></DashboardGraphHeadingExpandLinkCell>
    </DashboardGraphHeaderRow>
    <DashboardGraphInnerContainer id={`graph-container-${new Date().getTime()}`} ref={ref}>
      {children}
    </DashboardGraphInnerContainer>
  </DashboardGraphContainer>
  })

export default class Dashboard extends React.Component {

  constructor(props) {

    super(props)

    const layoutWidth=this.getLayoutWidth()
    const layoutHeight=this.getLayoutHeight()
    const isSmallScreen=(layoutWidth < styledTheme.breakpoints.lg)
    const mobileLandscapeView=(isSmallScreen && (layoutWidth > layoutHeight))

    this.state = {
      layoutWidth,
      layoutHeight,
      isSmallScreen,
      mobileLandscapeView,
      vtdGraphOptions: null,
      temperatureGraphOptions: null,
      windGraphOptions: null,
      relativeHumidityDeltaTGraphOptions: null,
      ghcopGraphOptions: null,
      gfdiGraphOptions: null,
      rainfallGraphOptions: null,
      dewPointGraphOptions: null,
      showVTD: true,
      showGhcop: true,
    }

    this.vtdGraphContainer = React.createRef()
    this.vtdGraphChart = React.createRef()
    this.temperatureGraphContainer = React.createRef()
    this.temperatureGraphChart = React.createRef()
    this.windGraphContainer = React.createRef()
    this.windGraphChart = React.createRef()
    this.relativeHumidityDeltaTGraphContainer = React.createRef()
    this.relativeHumidityDeltaTGraphChart = React.createRef()
    this.ghcopGraphContainer = React.createRef()
    this.ghcopGraphChart = React.createRef()
    this.gfdiGraphContainer = React.createRef()
    this.gfdiGraphChart = React.createRef()
    this.rainfallGraphContainer = React.createRef()
    this.rainfallGraphChart = React.createRef()
    this.dewPointGraphContainer = React.createRef()
    this.dewPointGraphChart = React.createRef()

    Highcharts.wrap(Highcharts.PlotLineOrBand.prototype, 'render', function (proceed) {
      var chart = this.axis.chart;
      proceed.call(this);
      if (!chart.seriesGroup) {
        chart.seriesGroup = chart.renderer.g('series-group')
          .attr({ zIndex: 3 })
          .add();
      }
      if (this.svgElem.parentGroup !== chart.seriesGroup) {
        this.svgElem
            .attr({ zIndex: this.options.zIndex })
            .add(chart.seriesGroup);
      }
      return this;
    })

  }

  componentDidMount = () => {
    this.loadDashboardContent(this.state)
    window.addEventListener('resize', this.resizeHandler)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resizeHandler)
  }

  componentDidUpdate = (oldProps,oldState) => {
    this.loadDashboardContent(oldState)
  }

  loadDashboardContent = (oldState) => {
    const awsType = this.props.site && this.props.site.aws_type
    const showVTD = (!['bom','hilltop'].includes(awsType))
    const showGhcop = (!['bom','hilltop'].includes(awsType))
    if (this.props.data && this.props.data.graphs && (
      (this.state.showVTD && !oldState.vtdGraphOptions) ||
      (this.state.showGhcop && !oldState.ghcopGraphOptions) ||
      !oldState.gfdiGraphOptions ||
      !oldState.temperatureGraphOptions ||
      !oldState.windGraphOptions ||
      !oldState.relativeHumidityDeltaTGraphOptions ||
      !oldState.rainfallGraphOptions ||
      !oldState.dewPointGraphOptions
    )) {
      this.setState({
        vtdGraphOptions: showVTD ? this.processedGraphOptions(this.props.data.graphs.vtd,this.vtdGraphContainer,this.vtdGraphChart,{ timezone_name: this.props.site.timezone_name }) : null,
        ghcopGraphOptions: showGhcop ? this.processedGraphOptions(this.props.data.graphs.ghcop,this.ghcopGraphContainer,this.ghcopGraphChart,{ timezone_name: this.props.site.timezone_name }) : null,
        gfdiGraphOptions: this.processedGraphOptions(this.props.data.graphs.gfdi,this.gfdiGraphContainer,this.gfdiGraphChart,{ timezone_name: this.props.site.timezone_name }),
        temperatureGraphOptions: this.processedGraphOptions(this.props.data.graphs.temperature,this.temperatureGraphContainer,this.temperatureGraphChart,{ timezone_name: this.props.site.timezone_name }),
        windGraphOptions: this.processedGraphOptions(this.props.data.graphs.wind,this.windGraphContainer,this.windGraphChart,{ timezone_name: this.props.site.timezone_name }),
        relativeHumidityDeltaTGraphOptions: this.processedGraphOptions(this.props.data.graphs.relative_humidity_delta_t,this.relativeHumidityDeltaTGraphContainer,this.relativeHumidityDeltaTGraphChart,{ timezone_name: this.props.site.timezone_name }),
        rainfallGraphOptions: this.processedGraphOptions(this.props.data.graphs.rainfall,this.rainfallGraphContainer,this.rainfallGraphChart,{ timezone_name: this.props.site.timezone_name }),
        dewPointGraphOptions: this.processedGraphOptions(this.props.data.graphs.dew_point,this.dewPointGraphContainer,this.dewPointGraphChart,{ timezone_name: this.props.site.timezone_name }),
        showVTD: showVTD,
        showGhcop: showGhcop,
      })
    }
    const highchartsCreditElArray = document.querySelectorAll('.highcharts-credits')
    if (highchartsCreditElArray.length) {
      highchartsCreditElArray.forEach((highchartsCredit) => {
        highchartsCredit.parentNode.removeChild(highchartsCredit)
      })
    }
  }

  getLayoutWidth = () => {
    if (typeof window === 'undefined') return styledTheme.breakpoints.lg
    return window.offsetWidth
  }

  getLayoutHeight = () => {
    if (typeof window === 'undefined') return (styledTheme.breakpoints.lg*9/16)
    return window.offsetHeight
  }

  processedGraphOptions = (options,containerRef,chartRef,extraOptions = {}) => {

    if (!options) {
      return {}
    }

    options['scrollbar'] = { enabled: true }
    options['legend'] = { enabled: false }
    options['rangeSelector'] = {
      enabled: true,
      inputEnabled: false,
      buttons: [{
        type: 'day',
        count: 1,
        text: '1d'
      },{
        type: 'day',
        count: 2,
        text: '2d'
      },{
        type: 'week',
        count: 1,
        text: '1w'
      }]
    }
    const xAxis = options['xAxis']
    const maxUnixTimeMoment = Moment(new Date(xAxis[0]['max']))
    const twoDaysAgoUnixtime = maxUnixTimeMoment.clone().subtract(2,'days')
    const xAxisRange = maxUnixTimeMoment.diff(twoDaysAgoUnixtime)
    xAxis[0]['min'] = xAxis[0]['max']-xAxisRange
    xAxis['startOnTick'] = true
    xAxis['endOnTick'] = true
    xAxis['ordinal'] = false

    const containerHeight = containerRef.current.offsetHeight
    const containerWidth = containerRef.current.offsetWidth
    options.chart['height'] = containerHeight
    options.chart['width'] = containerWidth

    const windDirectionSeries = options['series'].slice().filter(({id}) => id.match(/^wind_direction/))

    options.chart['events']['load'] = ((event) => {
      event.target['yAxis'].forEach((axis) => {
        if (axis.userOptions.id==="y_axis_km_h") {
          axis.update({
            min: axis['dataMin'],
            max: axis['dataMax'],
          })
        }
      })
      //event.target.redraw()
    })

    if (windDirectionSeries.length) {
      let initialDraw = true
      options.chart['events']['redraw'] = ((event) => {
        const chart = event.target
        const xAxis = chart.xAxis[0]
        if ((!initialDraw) && (xAxis.min===xAxis.oldMin) && (xAxis.max===xAxis.oldMax)) return true
        initialDraw = false
        const xAxisMin = xAxis['dataMin']
        const xAxisMax = xAxis['dataMax']
        windDirectionSeries.forEach((optionsSeries) => {
          const newData = []
          const chartSeries = chart.get(optionsSeries.id)
          chartSeries.points.forEach((chartDataPoint) => {
            const optionsDataPoint = optionsSeries.data.find((o) => o['x'] ? (chartDataPoint.x===o['x']) : (chartDataPoint.x===o[0]))
            const degrees = optionsDataPoint['degrees'] ? (optionsDataPoint['degrees'] || 0) : optionsDataPoint[2]
            const markerAngle = this.convertDegreesToIconAngle(degrees)
            const cardinal = optionsDataPoint['cardinal'] ? optionsDataPoint['cardinal'] : optionsDataPoint[3]
            const marker = (typeof markerAngle==='number') ?
              {
                enabled: false,
                symbol: `url(${Environment.DYNAMIC_ASSET_HOST}/icons/up-arrow-circular-button.png?rotation=${markerAngle})`,
                radius: 24,
              } :
              {
                enabled: false
              }
            const { x, y } = chartDataPoint
            newData.push({ x, y, marker, degrees, cardinal })
          })
          const noDataPoints = 20
          const incrementsPerItem = Math.floor(newData.length/noDataPoints)
          let iterator=0
          newData.forEach((chartDataPoint) => {
            if (iterator++===incrementsPerItem) {
              const degrees = chartDataPoint && chartDataPoint.degrees
              const markerAngle = this.convertDegreesToIconAngle(degrees)
              if (chartDataPoint && (typeof markerAngle==='number')) {
                chartDataPoint.marker = {
                  enabled: true,
                  symbol: `url(${Environment.DYNAMIC_ASSET_HOST}/icons/up-arrow-circular-button.png?rotation=${markerAngle})`,
                  radius: 16,
                }
              }
              iterator=0
            }
          })
          chartSeries.setData(newData,false,null,false)
        })

        //chart.redraw()

      })
    }

    options['yAxis'] = this.formatYAxis(options['yAxis'].slice())

    options['series'] = this.formatSeries(options['series'].slice())

    options['time'] = {
      getTimezoneOffset: function (timestamp) {
        return -Moment.tz(timestamp, extraOptions['timezone_name']).utcOffset()
      }
    }

    return options

  }

  resizeHandler = (e) => {

    const graphRefSets = [
      [this.vtdGraphContainer,this.vtdGraphChart],
      [this.temperatureGraphContainer,this.temperatureGraphChart],
      [this.windGraphContainer,this.windGraphChart],
      [this.relativeHumidityDeltaTGraphContainer,this.relativeHumidityDeltaTGraphChart],
      [this.ghcopGraphContainer,this.ghcopGraphChart],
      [this.gfdiGraphContainer,this.gfdiGraphChart],
      [this.rainfallGraphContainer,this.rainfallGraphChart],
      [this.dewPointGraphContainer,this.dewPointGraphChart],
    ]

    graphRefSets.forEach((graphRefSets) => {

      const container = graphRefSets[0].current
      const graph = graphRefSets[1].current

      if ((!container) || (!graph)) return true

      let last3Widths = []
      let last3Heights = []

      const resizeFunction = () => {
        const containerWidth = container.offsetWidth
        const containerHeight = container.offsetHeight
        const graphWidth = containerWidth-2
        const graphHeight = containerHeight-2
        graph.chart.setSize(
          graphWidth,
          graphHeight,
        )
        graph.chart.reflow()

      }

      const intervalId = setInterval(() => {
        last3Widths.push(container.offsetWidth)
        last3Heights.push(container.offsetHeight)
        last3Widths = last3Widths.slice(-3)
        last3Heights = last3Heights.slice(-3)
        if ((last3Widths.length < 3) || (last3Heights.length < 3)) {
          resizeFunction()
        } else {
          const widthTotal = last3Widths.reduce((n,total) => total+n,0)
          const heightTotal = last3Heights.reduce((n,total) => total+n,0)
          if (((widthTotal/3)===last3Widths[2]) && ((heightTotal/3)===last3Heights[2])) {
            clearInterval(intervalId)
          } else {
            resizeFunction()
          }
        }
      },500)
    })
  }

  formatSeries = (seriesArray) => seriesArray.map(series => {
    if (series.id.match(/^wind_direction/)) {
      return {
        ...series,
        turboThreshold: 0,
        tooltip: {
          pointFormatter: function() {
            if (this.degrees==null) return null
            return `<span style="color:${this.color}">\u25CF </span>${series.name}: <b>${this.cardinal} (${this.degrees}\u00B0)</b><br/>`
          }
        }
      }
    }
    return series
  })

  formatYAxis = (yAxesArray) => yAxesArray.map(axis => {
    if (axis.id==="y_axis_km_h") {
      return {
        ...axis,
        labels: {
          formatter: function() {
            if (parseInt(this.value) >= 0) return this.value;
            return null;
          }
        }
      }
    }
    return axis
  })

  convertDegreesToIconAngle(degrees) {
    const ret = (typeof degrees==='number') ?
      (degrees<180) ?
        degrees+180 :
        degrees-180 :
      null
    return ret
  }

  siteDataViewsButtonClickHandler = () => {
    const { site, routeToSiteDataViewsListHandler } = this.props
    ReactGA.event({
      category: 'Site Action',
      action: `View Site Data Views List from Dashboard`,
      label: site.name,
    })
    routeToSiteDataViewsListHandler.call(this,site.slug)
  }

  graphExpandButtonClickHandler = (dataViewName,dataViewSlug) => {
    const { site, routeToDataViewHandler } = this.props
    const siteSlug = site.slug
    const siteName = site.name
    ReactGA.event({
      category: 'Site Action',
      action: `Expand Graph from Dashboard`,
      label: `${siteName}:${dataViewName}`,
      site: site.name,
      data_view_name: dataViewName,
    })
    routeToDataViewHandler.call(this,siteSlug,dataViewSlug)
  }

  renderBody = (props,state,refs) => {
    const { data, site, awsType, routeToSiteDataViewsListHandler, fetching, fetched } = props
    const title = (data && data.site_name) ? `Dashboard: ${data.site_name}` : ''
    return <DataViewModalContainer>
      <LoadingIndicator show={fetching} />
      <HelmetTitle title={title} />
      {<ContainerHeading
        title={title}
        actionIcons={
          <ContainerHeadingActionIcon onClick={this.props.closeDataViewHandler}><CloseCircle /></ContainerHeadingActionIcon>
        }
      />}
      <DashboardContainer>
        { data && data.site_summary && routeToSiteDataViewsListHandler && <Toolbar dashboardToolbarExpandLinkClickHandler={this.siteDataViewsButtonClickHandler} siteSlug={site.slug} />}
        <DashboardTopRow>
          {data && data.location && <DashboardMapContainer><MapComponent data={data} location={data.location} /></DashboardMapContainer>}
          {data && data.key_indicators && data.key_indicators.vtd && <KeyIndicatorStatus heading={'Vertical Temperature Difference'} data={data.key_indicators.vtd} />}
          {data && data.key_indicators && data.key_indicators.ghcop && <KeyIndicatorStatus heading={'Grain Harvest Code of Practice (GHCoP, wind@2m)'} data={data.key_indicators.ghcop} />}
        </DashboardTopRow>
        { data && data.site_summary && <MetricsPanel site={site} statusArray={data.site_summary} awsType={awsType} />}
        {data && data.graphs && this.state.showVTD && <Graph
          title={"Vertical Temperature Difference"}
          state={state}
          ref={this.vtdGraphContainer}
          siteSlug={site.slug}
          dataViewSlug={'vertical-temperature-difference'}
          graphExpandButtonClickHandler={this.graphExpandButtonClickHandler}
        >
          {
            this.state.vtdGraphOptions && <HighchartsReact
              ref={this.vtdGraphChart}
              highcharts={Highcharts}
              constructorType={'stockChart'}
              options={this.state.vtdGraphOptions}
              allowChartUpdate={false}
            />
          }
        </Graph>
        }
        {data && data.graphs && this.state.showGhcop && <Graph
          title={"Grain Harvest Code of Practice (GHCoP, wind@2m)"}
          state={state}
          ref={this.ghcopGraphContainer}
          siteSlug={site.slug}
          dataViewSlug={'grain-harvest-code-of-practice-ghcop'}
          graphExpandButtonClickHandler={this.graphExpandButtonClickHandler}
        >
          {
            this.state.ghcopGraphOptions && <HighchartsReact
              ref={this.ghcopGraphChart}
              highcharts={Highcharts}
              constructorType={'stockChart'}
              options={this.state.ghcopGraphOptions}
              allowChartUpdate={false}
            />
          }
        </Graph>
        }

        {data && data.graphs && <Graph
          title={"Temperatures"}
          state={state}
          ref={this.temperatureGraphContainer}
          siteSlug={site.slug}
          dataViewSlug={'temperatures'}
          graphExpandButtonClickHandler={this.graphExpandButtonClickHandler}
        >
          {
            this.state.temperatureGraphOptions && <HighchartsReact
              ref={this.temperatureGraphChart}
              highcharts={Highcharts}
              constructorType={'stockChart'}
              options={this.state.temperatureGraphOptions}
              allowChartUpdate={false}
            />
          }
        </Graph>
        }
        {data && data.graphs && <Graph
          title={"Wind"}
          state={state}
          ref={this.windGraphContainer}
          siteSlug={site.slug}
          dataViewSlug={'wind'}
          graphExpandButtonClickHandler={this.graphExpandButtonClickHandler}
        >
          {
            this.state.windGraphOptions && <HighchartsReact
              ref={this.windGraphChart}
              highcharts={Highcharts}
              constructorType={'stockChart'}
              options={this.state.windGraphOptions}
              allowChartUpdate={false}
            />
          }
        </Graph>
        }
        {data && data.graphs && <Graph
          title={"Relative Humidity & Delta T"}
          state={state}
          ref={this.relativeHumidityDeltaTGraphContainer}
          siteSlug={site.slug}
          dataViewSlug={'relative-humidity-delta'}
          graphExpandButtonClickHandler={this.graphExpandButtonClickHandler}
        >
          {
            this.state.relativeHumidityDeltaTGraphOptions && <HighchartsReact
              ref={this.relativeHumidityDeltaTGraphChart}
              highcharts={Highcharts}
              constructorType={'stockChart'}
              options={this.state.relativeHumidityDeltaTGraphOptions}
              allowChartUpdate={false}
            />
          }
        </Graph>
        }
        {data && data.graphs && <Graph
          title={"Rainfall"}
          state={state}
          ref={this.rainfallGraphContainer}
          siteSlug={site.slug}
          dataViewSlug={'rainfall'}
          graphExpandButtonClickHandler={this.graphExpandButtonClickHandler}
        >
          {
            this.state.rainfallGraphOptions && <HighchartsReact
              ref={this.rainfallGraphChart}
              highcharts={Highcharts}
              constructorType={'stockChart'}
              options={this.state.rainfallGraphOptions}
              allowChartUpdate={false}
            />
          }
        </Graph>
        }
        {data && data.graphs && <Graph
          title={"Dew Point"}
          state={state}
          ref={this.dewPointGraphContainer}
          siteSlug={site.slug}
          dataViewSlug={'dew-point'}
          graphExpandButtonClickHandler={this.graphExpandButtonClickHandler}
        >
          {
            this.state.dewPointGraphOptions && <HighchartsReact
              ref={this.dewPointGraphChart}
              highcharts={Highcharts}
              constructorType={'stockChart'}
              options={this.state.dewPointGraphOptions}
              allowChartUpdate={false}
            />
          }
        </Graph>
        }
        {data && data.graphs && <Graph
          title={"GFDI"}
          state={state}
          ref={this.gfdiGraphContainer}
          siteSlug={site.slug}
          dataViewSlug={'gfdi'}
          graphExpandButtonClickHandler={this.graphExpandButtonClickHandler}
        >
          {
            this.state.gfdiGraphOptions && <HighchartsReact
              ref={this.gfdiGraphChart}
              highcharts={Highcharts}
              constructorType={'stockChart'}
              options={this.state.gfdiGraphOptions}
              allowChartUpdate={false}
            />
          }
        </Graph>
        }
      </DashboardContainer>
    </DataViewModalContainer>
  }

  render = () => this.renderBody(this.props,this.state,this.refs)

}
