import React from 'react'
import styled from 'styled-components'
import styledTheme from '../../styles/theme'

import Menu from './Menu.js'
import SiteHeader from './SiteHeader'
import SiteFooter from './SiteFooter'

import { ScreenWidthContainer } from '../../styles/styled-elements'

const SiteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${window.innerHeight}px;
  width: 100%;
`
const MainContent = styled.div`
  background-color: rgba(221,225,231,0.5);
  flex: 1;
  display: flex;
  flex-direction: column;
  @media (min-width: 0px) {
    padding: ${({noPadding}) => noPadding ? '0' : '10'}px;
    padding-top: ${({noPadding}) => noPadding ? '0' : '16'}px;
  };
  @media (min-width: ${styledTheme.breakpoints.md}px) {
    padding: ${({noPadding}) => noPadding ? '0' : '16'}px;
    padding-top: ${({noPadding}) => noPadding ? '0' : '20'}px;
  };
`

const SiteContainer = ({ children, noPadding }) => {
  return <ScreenWidthContainer>
    <SiteWrapper>
      <SiteHeader />
      <MainContent noPadding={noPadding}>
        {children}
      </MainContent>
    </SiteWrapper>
  </ScreenWidthContainer>
}

export default SiteContainer
