import { createGlobalStyle } from 'styled-components'
import styledTheme from './theme'

const GlobalStyles = createGlobalStyle`
  html, body {
    height: 100%;
    width: 100%;
    max-width: 1600px;
    padding: 0;
    margin: 0 auto;
    color: #444;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
  }

  body {
    margin: 0 auto;
    padding: 0;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: normal;

    a, a:link, a:hover, a:visited, a:active {
      color: ${styledTheme.colors.action_link};
    }
  }

  * {
    box-sizing: border-box;
  }

  #app {
    height: 100%;
  }

  span.nowrap {
    white-space: nowrap;
  }
`

export default GlobalStyles