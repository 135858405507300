import React from 'react'

import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'

import configureStore, { history } from './configureStore'

import axios from 'axios'
import injectTapEventPlugin from 'react-tap-event-plugin'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'

import logo from './logo.svg'
import GlobalStyles from './styles/global-styles'

import Map from './containers/map'
import Analytics from './components/Analytics'
import NewInfoAlertSplash from './containers/infoAlerts/NewInfoAlertSplash'
import ScheduledDataUpdater from './components/ScheduledDataUpdater'
import Menu from './components/layout/Menu'
import Login from './containers/login'
import Logout from './containers/login/Logout'

import { setAuthInterceptor, setAuthFromLocalStorage } from './utils/authUtils'
import Environment from './utils/environment'

try {
  injectTapEventPlugin()
} catch (e) {}

setAuthInterceptor()
setAuthFromLocalStorage()

axios.defaults.baseURL = Environment.API_URL
axios.defaults.headers.common['Accept'] = `application/json`

const store = configureStore()

const ScrollToTop = ({ children }) => {
  window.scrollTo(0, 0)
  return (
    <div>
      {children}
    </div>
  )
}

const SaveLastUrl = () => {
  window.lastLocation = { ...window.thisLocation }
  const { host, href, pathname } = window.location
  window.thisLocation = { host, href, pathname }
  return null
}

const getTitle = () => {
  let ret
  ret = 'TainData Panorama'
  return ret
}

const HelmetRootTemplate = () => <Helmet
  titleTemplate={`%s :: ${getTitle()}`}
  defaultTitle={`${getTitle()}`}
  onChangeClientState = {(newState) => {
    const title = newState['title']
    if (title && (title.indexOf('undefined')<0)) {
      ReactGA.pageview(window.location.pathname + window.location.search)
    }
  }}
  meta={[
    { property: 'og:title', content: `${getTitle()}` },
    { property: 'charset', content: 'utf-8' }
  ]}
/>

ReactGA.initialize(Environment.GOOGLE_ANALYTICS_ID)

const MesonetApp = (props) => (
  <Provider store={store} key="provider">
    <ConnectedRouter history={history}>
      <ScrollToTop>
        <GlobalStyles />
        <HelmetRootTemplate />
        <SaveLastUrl />
        <ScheduledDataUpdater />
        <NewInfoAlertSplash />
        <Menu />
        <Switch>
          <Route exact path="/" component={Map} />
          <Route exact path="/map/:site_slug/:data_view_slug" component={Map} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />
        </Switch>
      </ScrollToTop>
    </ConnectedRouter>
  </Provider>
)

export default MesonetApp
