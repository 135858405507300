import React from 'react'
import { isBrowser } from "react-device-detect"

import SiteNameHoverPopup from './SiteNameHoverPopup'

import { MarkerWrapper, MarkerDiv, OctagonMarkerDiv } from './styled-elements'

import WindArrow from '../icons/wind-arrow.svg'
import OctagonMarker from '../icons/OctagonMarker'

const Marker = ({ metricKey, metricHash, siteSummaryDataHash, awsType, windDirection, onClick, onMouseOver, onMouseOut, divRef, siteName }) => {

  const bgColor = metricHash && metricHash['styles'] && metricHash['styles']['background_color']
  const fontColor = metricHash && metricHash['styles'] && metricHash['styles']['font_color']
  let value = metricHash && metricHash['value']

  if (['vtd','ghcop','wind_speed_2m'].includes(metricKey)) {
    switch (awsType) {
      case "bom":
        value="B"
        break
      case "hilltop":
        value="H"
        break
      default:
        value=value
    }
  }

  let zIndexLayer='top'
  if (awsType==='bom') zIndexLayer='bottom'
  if (awsType==='hilltop') zIndexLayer='middle'

  const arrowAngle = (windDirection==undefined) ?
    null :
    (windDirection==="C") ?
      "C" :
      (windDirection <= 180) ?
        windDirection+180 :
        windDirection-180

  const bgImage = (typeof windDirection === "number") ?
    WindArrow :
    null

  const noWindFadedOutlineColor = 'rgba(0, 0, 0, 0.25)'

  if (awsType==='bom') {
    return <MarkerWrapper zIndexLayer={zIndexLayer}>
      <OctagonMarkerDiv
        ref={divRef}
        onClick={onClick}
        onMouseOver={isBrowser ? onMouseOver : undefined}
        onMouseOut={isBrowser ? onMouseOver : undefined}
        bgColor={bgColor}
        bgImage={bgImage}
        angle={arrowAngle}
        noWindFadedOutlineColor={noWindFadedOutlineColor}
        style={{
          color: `${fontColor ? fontColor : '#000'}`
        }}
      >
        <OctagonMarker fill={bgColor} />
        {value}
      </OctagonMarkerDiv>
    </MarkerWrapper>
  }

  return <MarkerWrapper zIndexLayer={zIndexLayer}>
    <MarkerDiv
      ref={divRef}
      onClick={onClick}
      onMouseOver={isBrowser ? onMouseOver : undefined}
      onMouseOut={isBrowser ? onMouseOver : undefined}
      bgImage={bgImage}
      angle={arrowAngle}
      style={{
        borderColor: `${arrowAngle ? 'black' : noWindFadedOutlineColor}`,
        backgroundColor: `${bgColor ? bgColor : '#FFF'}`,
        color: `${fontColor ? fontColor : '#000'}`,
      }}
    >
      {value}
    </MarkerDiv>
  </MarkerWrapper>

}

export default class MapMarker extends React.Component {

  constructor(props) {
    super(props)
    this.statusPopup = React.createRef()
    this.markerDiv = React.createRef()
  }

  componentDidUpdate(prevProps) {
  }

  getStatusPopupBoundingClientRect = () => {
    if (!this.statusPopup.current) return null
    return this.statusPopup.current.getBoundingClientRect()
  }

  getCenterPosition = () => {

  }

  renderBody = ({ value, metricKey, metricHash, showSiteNameHoverPopup, clickHandler, mouseOverHandler, mouseOutHandler, name, slug, location, statusHash, siteSummaryDataHash, awsType, lastUpdated, timezone, statusPopupCloseIconClickHandler, routeToDataViewHandler }) => {
    let windDirection = siteSummaryDataHash && siteSummaryDataHash.wind_direction
    if (siteSummaryDataHash) {
      if (metricKey==='wind_speed_2m') windDirection = siteSummaryDataHash.wind_direction_2m
      if (metricKey==='wind_speed_10m') windDirection = siteSummaryDataHash.wind_direction_10m
      if (metricKey==='ghcop') windDirection = siteSummaryDataHash.wind_direction_ghcop
    }
    let dataIsFresh = true
    if ((new Date().getTime()-lastUpdated) > 3600000) {
      dataIsFresh = false
      windDirection = null
      metricHash['styles'] = {
        background_color: "rgba(255, 255, 255, 0.5)",
        font_color: "rgba(0, 0, 0, 0.3)",
        font_weight: "normal",
      }
      metricHash['value'] = "–"
      siteSummaryDataHash = { ...siteSummaryDataHash, ...{ data_array: siteSummaryDataHash.data_array.map((o) => ({...o, ...{value_str: "–"}}))}}
    }
    return (
      <div>
        {(showSiteNameHoverPopup) && <SiteNameHoverPopup name={name}/>}
        <Marker
          metricKey={metricKey}
          metricHash={metricHash}
          siteSummaryDataHash={siteSummaryDataHash}
          awsType={awsType}
          windDirection={windDirection}
          onClick={clickHandler}
          onMouseOver={mouseOverHandler}
          onMouseOut={mouseOutHandler}
          divRef={this.markerDiv}
	        siteName={name}
        />
      </div>
    )
  }

  render() {
    return this.renderBody(this.props)
  }
}
