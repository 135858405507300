const initialState = {
  list: [],
  item: null,
  fetching: false,
  fetched: false,
  errors: null,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "GROWING_SEASONS_FETCH_PENDING":
      return {
        ...state,
        fetching: true,
        fetched: false,
        errors: null,
      }
    case "GROWING_SEASONS_FETCH_REJECTED":
      return {
        ...state,
        fetching: false,
        errors: action.payload.response ? action.payload.response.data.errors : action.payload.message,
      }
    case "GROWING_SEASONS_FETCH_FULFILLED":
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: action.payload.data,
        errors: null,
      }
    default:
      return state
  }
}
