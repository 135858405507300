import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'
import styled from 'styled-components'
import styledTheme from '../../styles/theme'
import { ScreenWidthCss } from '../../styles/styled-elements'
import { openMenu } from '../../actions/MenuActions'

import PanormaWordmark from '../../assets/panormaWordmark.svg'
import BarsSolid from '../../components/icons/BarsSolid'

const Wrapper = styled.header`
  position: relative;
  top: 0;
  height: 50px;
  background-color: #FFF;
  z-index: 1;
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 5px #888;
  max-width: 100%;
  ${ScreenWidthCss}
`
const LogoDiv = styled.div`
  display: inline-flex;
  width: fit-content;
  align-items: center;
  @media (min-width: 0px) {
    padding: 6px;
    padding-left: 10px;
  };
  @media (min-width: ${styledTheme.breakpoints.md}px) {
    padding: 10px;
    padding-left: 16px;
  };
`
const PanormaWordmarkLogo = styled.div`
  height: 32px;
  width: 110px;
  background-image: url(${PanormaWordmark});
`
const MenuDiv = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  height: 100%;
  display: inline-flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  @media (min-width: 0px) {
    padding: 6px;
    padding-right: 10px;
  };
  @media (min-width: ${styledTheme.breakpoints.md}px) {
    padding: 10px;
    padding-right: 16px;
  };
`
const MenuIconContainer = styled.div`
  display: block;
  cursor: pointer;
  svg {
    height: 24px;
    width: 24px;
    color: ${styledTheme.colors.action_link};
  }
`

class SiteHeader extends React.Component {

  openMenuButtonClickHandler = () => {
    this.props.dispatch(openMenu())
    ReactGA.event({
      category: 'Site Action',
      action: 'Menu Action',
      label: 'Open - From Header Button'
    })
  }

  render = () => <Wrapper>
    <LogoDiv>
      <Link to={'/'}>
        <PanormaWordmarkLogo />
      </Link>
    </LogoDiv>
    <MenuDiv>
      <MenuIconContainer onClick={this.openMenuButtonClickHandler.bind(this)}><BarsSolid /></MenuIconContainer>
    </MenuDiv>
  </Wrapper>
}

export default connect()(SiteHeader)
