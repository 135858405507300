import React from 'react'

import { ColorBarContainer, ColorBarNamesColumn, ColorBarColorsColumn, ColorScaleValueCell, ColorGradientBar } from './styled-elements'

export default class DeltaTLegend extends React.Component {

  renderBody = ({ metricDisplayStatuses, unit }) => <ColorBarContainer>
    <ColorBarNamesColumn>
      <ColorScaleValueCell position={'top'}>
        {metricDisplayStatuses[0] && `${parseInt(metricDisplayStatuses[0].threshold_value)}${unit}`}
      </ColorScaleValueCell>
      <ColorScaleValueCell position={'bottom'}>
        {metricDisplayStatuses[1] && `${parseInt(metricDisplayStatuses[1].threshold_value)}${unit}`}
      </ColorScaleValueCell>
    </ColorBarNamesColumn>
    <ColorBarColorsColumn>
      <ColorGradientBar
        height={100}
        colors={metricDisplayStatuses.map(({background_color}) => background_color)}
      />
    </ColorBarColorsColumn>
  </ColorBarContainer>

  render() {
    return this.renderBody(this.props)
  }
}
