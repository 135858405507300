import React from 'react'
import ReactGA from 'react-ga'
import { connect } from 'react-redux'

class Analytics extends React.Component {

  componentDidMount = () => {
  }

  render = () => null

}

export default connect(
  (store, props) => { 
    return null
  },
)(Analytics)

