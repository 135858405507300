import React from 'react'
import { connect } from 'react-redux'
import LoginForm from '../../components/login_form'
import SiteContainer from '../../components/layout/SiteContainer'
import { signOut } from '../../actions/AuthActions'
import HelmetTitle from '../../components/layout/HelmetTitle'

import { fetchAwsNetwork } from '../../actions/AwsNetworkActions'

class Login extends React.Component {

  componentDidMount() {
    console.log('XXXX this.props',this.props)
    const { props } = this
    const { dispatch, match, awsNetwork, isSignedIn} = props
    dispatch(signOut())
    if (!awsNetwork || !Object.keys(awsNetwork).length) {
      const hostname = (window.location.hostname==="localhost") ? "riverlandmalleemesonet.com" : window.location.hostname
      dispatch(fetchAwsNetwork(hostname))
    } else {
    }
  }

  componentDidUpdate() {
    console.log('KKK this.props',this.props)
  }

  renderBody = (props,state) => <SiteContainer isSignedIn={props.apiAuth.isSignedIn}>
    <HelmetTitle title={'Login'} />
    <LoginForm
      apiAuth={props.apiAuth}
      awsNetwork={props.awsNetwork}
      dispatch={props.dispatch}
    />
  </SiteContainer>

  render = () => this.renderBody(this.props,this.state)

}

export default connect(
  (store, props) => ({
    apiAuth: store.apiAuth,
    router: store.router,
    awsNetwork: store.awsNetworks && store.awsNetworks.awsNetwork,
  }),
)(Login)
