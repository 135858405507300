import styled from 'styled-components'

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 130px;
  bottom: 60px;
`
export const LoginInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  background-color: #FFF;
  padding: 20px;
  border: 1px solid #979797;

`
export const LoginHeading = styled.h1`
  font-size: 20px;
`
export const Form = styled.form`
  width: 100%;
  font-size: 1.125rem;
  @media (min-width: 768px) {
    width: 400px;
    padding: 0 40px;
  }
  input {
    font-size: 1.125rem;
  }
`
export const FieldsWrapper = styled.div`
  margin: 20px 0;
`
export const FieldDiv = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    margin-bottom: 20px;
  }
`
export const SubmitWrapper = styled.div`
  text-align: center;
  padding: 20px 0;
`
