import styled from 'styled-components'

export const InfoAlertSplashScreenOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({show}) => show ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
`
export const MenuCloseIconContainer = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
  cursor: pointer;
  svg {
    height: 26px;
    width: 26px;
    fill: #666;
  }
`
export const InfoAlertsSplashContainer = styled.div`
  height: 50%;
  width: 50%;
  @media (max-width: 768px) {
	height: 80%;
	width: 80%;
  }  
  position: relative;
  z-index: 12;
  background-color: white;
  border: 2px solid #666;
  border-radius: 6px;
  padding: 10px;
`
export const InfoAlertsSplashList = styled.div`
  height: 100%;
  overflow: scroll;
`
export const InfoAlertsSplashListItem = styled.div`
  padding-bottom: 16px;
  border-bottom: 1px solid #AAA;
  cursor: pointer;
`
export const InfoAlertsSplashListItemHeading = styled.h3`
  margin-bottom: 0;  
`
export const InfoAlertsShowTopHeading = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 2rem;
  a, a:link, a:active, a:visited, a:hover {
    color: #666;
    text-decoration: none;
  }
`
export const InfoAlertsH1 = styled.h1`
  font-size: 24px;
  max-width: 600px;
`
export const IndexItemContainer = styled.div`
  display: block;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.6rem;
  border-top: 1px solid #999;
  &:last-child {
    border-bottom: 1px solid #999;  
  }
  background-color: ${({unread}) => {
    return (unread ? 'rgba(74, 145, 226, 0.2)' : 'none')
  }};
`
export const IndexItemHeading = styled.h2`
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 0.4rem;
  max-width: unset;
`
export const IndexItemDatePublished = styled.div`
  font-size: 0.8rem;
  margin-bottom: 0;
`
export const IndexItemDateLabel = styled.strong`
  font-weight: 500;
`

export const IndexItemTeaser = styled.div`
  font-size: 1rem;
  margin-bottom: 0;
  p {
    max-width: unset;  
  }  
`
export const IndexItemReadMoreLink = styled.div`
  margin-bottom: 0.2rem;
  font-size: 0.8rem;
  font-weight: 700;
`
