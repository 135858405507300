import axios from 'axios'

export function fetchDataViews(companyParam,siteParam) {
  return (dispatch, getState) => {
    dispatch({
      type: 'DATA_VIEWS_FETCH',
      payload: axios(`/companies/${companyParam}/sites/${siteParam}/data_views`)
    })
  }
}
export function setDataViewSummary(dataViewSummary) {
  return (dispatch, getState) => {
    dispatch({
      type: 'DATA_VIEW_SUMMARY_SET',
      meta: {
        dataViewSummary: dataViewSummary
      }
    })
  }
}
export function setAddManualInputFormVisibility(visible) {
  return (dispatch, getState) => {
    dispatch({
      type: 'DATA_VIEW_ADD_MANUAL_INPUT_FORM_VISIBLE',
      meta: {
        visible: visible
      }
    })
  }
}
export function fetchDataView(siteParam,dataViewParam) {
  return (dispatch, getState) => {
    dispatch({
      type: 'DATA_VIEW_FETCH',
      payload: axios(`/sites/${siteParam}/data_views/${dataViewParam}`)
    })
  }
}
export function fetchDataViewHash(siteParam,dataViewParam) {
  return (dispatch, getState) => {
    dispatch({
      type: 'DATA_VIEW_HASH_FETCH',
      payload: axios(`/sites/${siteParam}/data_views/${dataViewParam}/data_view_hash`)
    })
  }
}
export function fetchMemberGrowingSeasonDataViewHash(growingSeasonParam,dataViewParam) {
  return (dispatch, getState) => {
    dispatch({
      type: 'DATA_VIEW_HASH_FETCH',
      payload: axios(`/members/growing_seasons/${growingSeasonParam}/data_views/${dataViewParam}/data_view_hash`)
    })
  }
}
export function clearDataViewHash() {
  return (dispatch, getState) => {
    dispatch({
      type: 'DATA_VIEW_HASH_CLEAR',
      meta: {
        data: null
      }
    })
  }
}


// export function updateDataViewHash(siteId,dataViewGroupId,params) {
//   return (dispatch, getState) => {
//     dispatch({
//       type: 'DATA_VIEW_HASH_UPDATE',
//       payload: axios.put(`/sites/${siteId}/data_views/${dataViewGroupId}`)
//     })
//   }
// }
