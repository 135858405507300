import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import GoogleMapReact from 'google-map-react'
import ReactGA from 'react-ga'
import { isMobile } from "react-device-detect"
import Select from 'react-select'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { v4 as uuidv4 } from 'uuid'
import { DateTime } from "luxon"

import Environment from '../../utils/environment'
import styledTheme from '../../styles/theme'

import SiteContainer from '../../components/layout/SiteContainer'
import HelmetTitle from '../../components/layout/HelmetTitle'

import { DisclaimerContainer, DisclaimerCloseIcon, ModalAndMenuScreenOverlay, UserLocationSettingsButtonContainer, UserLocationSettingsButtonInner } from './styled-elements'

import { fetchAwsNetwork } from '../../actions/AwsNetworkActions'
import { fetchSites, fetchSiteSettings } from '../../actions/SiteActions'
import { fetchDataViewHash, clearDataViewHash } from '../../actions/DataViewActions'
import { fetchAppSettings } from '../../actions/AppSettingActions'
import { fetchMetricDisplayStatuses } from '../../actions/MetricDisplayStatusActions'

import { fetchGrowingLocations, updateGrowingLocation } from '../../actions/GrowingLocationActions'
import { fetchGrowingSeasons, updateGrowingSeason } from '../../actions/GrowingSeasonActions'

import MapMarker from '../../components/map/MapMarker'
import MapDraggableStar from '../../components/map/MapDraggableStar'
import MobileStatusPopup from '../../components/map/MobileStatusPopup'
import Legend from '../../components/map/Legend'
import OptionsPanel from '../../components/map/OptionsPanel'
import GrowingSeasonSettingsPanel from '../../components/map/GrowingSeasonSettingsPanel'
import Dashboard from '../../components/dataViews/Dashboard'
import Graph from '../../components/dataViews/Graph'

import MapSettings from '../../components/icons/MapSettings'
import mapSettings from '../../components/icons/map-settings.svg'


const MetricSelect = ({value,options,onChange}) => {
  const selectedOption = options.find((o) => o.value===value)
  const customStyles = {
    container: () => ({
      width: 220,
    })
  }
  return <div style={{ position: 'absolute', zIndex: 2, top: '20px', left: '10px' }}>
    <Select
      options={options}
      value={selectedOption}
      styles={customStyles}
      onChange={onChange}
      isSearchable={false}
    />
  </div>
}

const OptionsButton = ({clickHandler}) => {

  const StyledButton = withStyles({
    root: {
      backgroundColor: 'white',
      borderRadius: '4px',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'rgb(204, 204, 204)',
      padding: '2px 8px',
      '&:hover': {
        backgroundColor: '#CCC',
      }
    },
    label: {
      fontSize: '14px',
      textTransform: 'capitalize',
      fontWeight: 'normal',
      fontFamily: styledTheme.fonts.button,
    },
  })(Button)

  const buttonCmp = <div style={{ position: 'absolute', zIndex: 1, top: '80px', left: '10px' }}>
    <StyledButton onClick={clickHandler.bind(this)}>
      Options...
    </StyledButton>
  </div>

  return buttonCmp
}

const UserLocationSettingsButton  = ({clickHandler, children, auth}) => {

  const ret = <UserLocationSettingsButtonContainer>
    <UserLocationSettingsButtonInner onClick={clickHandler.bind(this)}>
      {children}
    </UserLocationSettingsButtonInner>
  </UserLocationSettingsButtonContainer>

  return ret

}

const getMethods = (obj) => {
  let properties = new Set()
  let currentObj = obj
  do {
    Object.getOwnPropertyNames(currentObj).map(item => properties.add(item))
  } while ((currentObj = Object.getPrototypeOf(currentObj)))
  return [...properties.keys()]
}

const UserLocationSettingsMenuConfirmationBoxContainer  = ({visible, content, cancelBtnClickHandler, saveButtonClickHandler}) => {

  if (!visible) return null

  const styles = {
    outer: {
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: '#666',
      borderRadius: 3,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      padding: 10,
      border: '2px solid black'
    },
    content: {
      display: 'inline-flex',
    },
    buttonsContainer: {
      display: 'inline-flex',
      justifyContent: 'flex-end',
      paddingTop: '10px',
    },
    button: {
      border: '2px solid #2F6BE1',
      borderRadius: '3px',
      padding: '4px 8px',
      fontSize: '12px',
      color: '#2F6BE1',
      marginLeft: '10px',
      cursor: 'pointer',
    },
  }

  const ret = <div style={{ position: 'absolute', width: 320, zIndex: 200, bottom: '10px', left: '10px' }}>
    <div style={styles.outer}>
      {content.map( (item,index) => (<div style={styles.content} key={index}>
          {item}
        </div>)
      )}
      <div style={styles.buttonsContainer}>
        <div style={styles.button} onClick={cancelBtnClickHandler} key={1}>
          Cancel
        </div>
        <div style={styles.button} onClick={saveButtonClickHandler} key={2}>
          Save
        </div>
      </div>
    </div>
  </div>

  return ret

}

const UserLocationSettingsMenuContainer  = ({visible, overlayClickHandler, children}) => {

  if (!visible) return null

  const style = {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#666',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    zIndex: 22,
  }

  const ret = <ModalAndMenuScreenOverlay onClick={e => overlayClickHandler(e)}>
    <div style={{ position: 'absolute', zIndex: 1, bottom: '105px', left: '10px' }}>
      <div style={style}>
        {children}
      </div>
    </div>
  </ModalAndMenuScreenOverlay>

  return ret

}

const UserLocationSettingsMenuItem = ({text, clickHandler}) => {

  const style = {
    padding: 12,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000',
    backgroundColor: 'white',
    cursor: 'pointer',
  }

  const ret = <div style={style} onClick={clickHandler}>
    {text}
  </div>

  return ret

}


const Disclaimer = ({textObject,disclaimerHidden,closeIconHandler}) => !disclaimerHidden && <DisclaimerContainer>
  <DisclaimerCloseIcon onClick={closeIconHandler}/>
  {textObject && textObject.value}
</DisclaimerContainer>

class Map extends React.Component {

  constructor(props) {
    super(props)
    const defaultMetricToDisplay = 'air_temperature'
    const savedMetricToDisplay = localStorage.getItem('metricToDisplay')
    localStorage.setItem('metricToDisplay',savedMetricToDisplay || defaultMetricToDisplay)
    const defaultMapTypeId = 'roadmap'
    const savedMapTypeId = localStorage.getItem('mapTypeId')
    localStorage.setItem('mapTypeId',savedMapTypeId || defaultMapTypeId)
    const defaultMapShowAllAwsNames = false
    const savedMapShowAllAwsNamesString = localStorage.getItem('mapShowAllAwsNames')
    const savedMapShowAllAwsNames = (savedMapShowAllAwsNamesString==='true') ? true : false
    localStorage.setItem('mapShowAllAwsNames',savedMapShowAllAwsNames || defaultMapShowAllAwsNames)
    const savedMapZoom = null //localStorage.getItem('savedMapZoom')
    const savedMapCenter = null // localStorage.getItem('savedMapCenter') ? JSON.parse(localStorage.getItem('savedMapCenter')) : null

    //const savedHomeMarker = localStorage.getItem('savedHomeMarker') ? JSON.parse(localStorage.getItem('savedHomeMarker')) : null

    let initCenter;
    let initZoom;
    initCenter = {lat: -33.889260068715515, lng: 138.1788284834479}
    initZoom = 7

    initZoom = savedMapZoom ? parseInt(savedMapZoom) : initZoom
    initCenter = savedMapCenter ? savedMapCenter : initCenter

    //let homeMarker = savedHomeMarker ? savedHomeMarker : {}

    console.log("XXX savedMetricToDisplay",savedMetricToDisplay)
    console.log("XXX defaultMetricToDisplay",defaultMetricToDisplay)

    this.state = {
      center: { ...initCenter },
      zoom: initZoom,
      attributes_select_list: [],
      disclaimerHidden: true, //eval(localStorage.getItem('mapViewDisclaimerHidden')) || false,
      userSettingsMenuVisible: false,
      metric_to_display: savedMetricToDisplay || defaultMetricToDisplay,
      showPopupId: null,
      showSiteNameHoverPopupId: null,
      mapVars: null,
      optionsPanelOpen: false,
      mapTypeId: savedMapTypeId || defaultMapTypeId,
      mapShowAllAwsNames: savedMapShowAllAwsNames || defaultMapShowAllAwsNames,
      draggable: true,
      homeMarkers: [],
      origHomeMarker: null,
      setMapCenterZoomLevelDialogVisible: false,
      setHomeLocationDialogVisible: false,
      savedMapZoom: savedMapZoom ? parseInt(savedMapZoom) : null,
      savedMapCenter: savedMapCenter ? savedMapCenter : null,
      growingSeasonSettingsModalVisible: false,
      activeGrowingSeason: {
        name: '',
        sowingDate: '',
        emergenceDate: '',
        floralInitiationDate: '',
        harvestDate: '',
        remoteId: '',
      },
      origActiveGrowingSeason: null,
    }
  }

  componentDidMount() {
    const { props } = this
    const { dispatch, match , awsNetwork, awsNetworkCentralLocation, awsNetworkMapZoomLevel, apiAuth, isSignedIn} = this.props
    if (!awsNetwork || !Object.keys(awsNetwork).length) {
      const hostname = (window.location.hostname==="localhost") ? "riverlandmalleemesonet.com" : window.location.hostname
      dispatch(fetchAwsNetwork(hostname))
    } else {
    }
    dispatch(fetchSites())
    dispatch(fetchSiteSettings())
    dispatch(fetchAppSettings())
    dispatch(fetchMetricDisplayStatuses())
    const siteSlug = match.params.site_slug
    const dataViewSlug = match.params.data_view_slug
    if (dataViewSlug) dispatch(fetchDataViewHash(siteSlug,dataViewSlug))
    const updateHash = {}
    if (awsNetworkCentralLocation && !this.state.savedMapCenter) {
      updateHash['center'] = awsNetworkCentralLocation
    }
    if (awsNetworkMapZoomLevel && !this.state.savedMapZoom) {
      updateHash['zoom'] = awsNetworkMapZoomLevel
    }
    if (isSignedIn) {
      dispatch(fetchGrowingLocations())
      dispatch(fetchGrowingSeasons())
    }
    this.setState(updateHash)
  }

  getMapMarkers = (props,state) => {
    const { isSignedIn } = props
    const ret = props.sites.sites.map(({id, name, slug, location, current_data_hash, current_data_last_updated, timezone_name, aws_type },index) => {
      const metricKey = this.state['metric_to_display']
      const metricHash = (current_data_hash['data_array'] && current_data_hash['data_array'].find(({metric_id}) => metric_id===metricKey )) || {}
      return <MapMarker
        key={index}
        lat={location.lat}
        lng={location.lng}
        draggable={true}
        position={'top-left'}
        awsType={aws_type}
        showSiteNameHoverPopup={(this.state.mapShowAllAwsNames) || (id===state.showSiteNameHoverPopupId)}
        clickHandler={this.mapMarkerClickHandler.bind(this,id)}
        mouseOverHandler={this.mapMarkerMouseOverHandler.bind(this,id)}
        mouseOutHandler={this.mapMarkerMouseOutHandler.bind(this,id)}
        name={name}
        slug={slug}
        location={location}
        siteSummaryDataHash={current_data_hash}
        lastUpdated={current_data_last_updated}
        timezone={timezone_name}
        closeStatusPopupHandler={this.closeStatusPopupHandler.bind(this)}
        metricHash={metricHash}
        metricKey={metricKey}
        routeToDataViewHandler={this.routeToDataView}
      />
    })
    const stateHomeMarkersArray = state.homeMarkers || []
    if (stateHomeMarkersArray.length || this.state.setHomeLocationDialogVisible) {
      if ((!stateHomeMarkersArray.length || !Object.keys(this.state.homeMarkers[0]).length) && this.state.setHomeLocationDialogVisible) {
        console.log("XXX TRUE - newHomeMarker needed")
        const newHomeMarker = {}
        newHomeMarker['location_coords'] = { ...this.state.center }
        newHomeMarker['name'] = 'Home'
        newHomeMarker['remoteId'] = uuidv4()
        stateHomeMarkersArray.push(newHomeMarker)
        this.setState({ homeMarkers: stateHomeMarkersArray})
      }

      if (isSignedIn) {
        let keyVal = 2000
        stateHomeMarkersArray.forEach((homeMarker) => {
          ret.push(<MapDraggableStar
            name={homeMarker.name}
            draggableHomeMarker={true}
            lat={homeMarker['location_coords'].lat}
            lng={homeMarker['location_coords'].lng}
            remoteId={homeMarker['remoteId']}
            key={`${++keyVal}`}
            ref={`activeMapmarkerStar${keyVal}`}
          />)
        })
      }

    }
    return ret
  }

  metricSelectChangeHandler = (selectedOption) => {
    this.setState({metric_to_display: selectedOption['value']})
    localStorage.setItem('metricToDisplay',selectedOption['value'])
    ReactGA.event({
      category: 'Site Action',
      action: 'Changed Map View Selected Metric',
      label: selectedOption['label'].replace("@"," at "),
    })
  }

  renderShowDataView(props,state) {
    const site = props.site
    const dataView = props.dataView
    const { dispatch } = props
    if (!site || !site.id) return null
    if (dataView) {
      switch (dataView.data_view_type_category) {
        case 'dashboard':
          if (dataView.data_view_type_key==="dashboard") return <Dashboard
            closeDataViewHandler={this.dataViewCloseDataViewHandler}
            dispatch={dispatch}
            site={site}
            dataView={dataView}
            data={props.dataViewHash}
            awsType={site.aws_type}
            routeToSiteDataViewsListHandler={this.routeToSiteDataViewsList}
            routeToDataViewHandler={this.routeToDataView}
            fetching={props.dataViewHashFetching}
            fetched={props.dataViewHashFetched}
          />
          break
        case 'graph':
          const options = props.dataViewHash || {}
          const windDirectionSeries = props.windDirectionSeries || {}
          return <Graph
            closeDataViewHandler={this.dataViewCloseDataViewHandler}
            dispatch={dispatch}
            site={site}
            dataView={dataView}
            options={options}
            windDirectionSeries={windDirectionSeries}
            dataViewSummary={props.dataViews}
            ref={this.graphComponent}
            fetching={props.dataViewHashFetching}
            fetched={props.dataViewHashFetched}
          />
          break
        default:
          return null
      }
    }
  }

  repositionMapCenterForStatusPopup = () => {

    let testCount = 0
    const activeMapMarker = this.refs['active-mapmarker-' + this.state.showPopupId]

    const intervalId = setInterval(() => {
      if (testCount > 10) {
        clearInterval(intervalId)
        return true
      }
      testCount++
      if (!activeMapMarker) {
        return true
      }
      const statusPopupBoundingClientRect = activeMapMarker.getStatusPopupBoundingClientRect()
      if (!statusPopupBoundingClientRect) {
        return true
      }
      testCount = 0

      if (statusPopupBoundingClientRect.y < 50) {

        if (this.state.mapVars) {
          const { bounds, size } = this.state.mapVars
          const { x, y } = this.getDegreesPerPixel(bounds, size)
          const oldCenter = this.state.center
          const newLat = oldCenter.lat+((Math.abs(statusPopupBoundingClientRect.y)+70)*y)
          this.setState({center: {lat: newLat, lng: oldCenter.lng }})
        }
        clearInterval(intervalId)
        return true

      }
      clearInterval(intervalId)
    },200)

  }

  componentDidUpdate = (prevProps, prevState) => {
    const { props } = this
    const { dispatch, match, awsNetworkCentralLocation, awsNetworkMapZoomLevel, isSignedIn } = this.props
    const siteSlug = match.params.site_slug
    const dataViewSlug = match.params.data_view_slug
    const prevDataViewSlug = prevProps.match.params.data_view_slug
    const stateHash = {}
    if (dataViewSlug && (dataViewSlug!==prevDataViewSlug)) dispatch(fetchDataViewHash(siteSlug,dataViewSlug))
    if (awsNetworkCentralLocation!==prevProps.awsNetworkCentralLocation && !this.state.savedMapCenter) {
      stateHash['center'] = awsNetworkCentralLocation
      stateHash['zoom'] = awsNetworkMapZoomLevel
    }
    if (isSignedIn && !prevProps.isSignedIn) {
      dispatch(fetchGrowingLocations())
      dispatch(fetchGrowingSeasons())
    }
    if (props.growingLocations.list!==prevProps.growingLocations.list) {
      const homeMarkers = props.growingLocations.list.map((o) => {
        return {
          ...{
            name: o['name'],
            remoteId: o['remote_id'],
            nearestSiteSlug: o['nearest_site_slug'],
            timezoneName: o['timezone_name'],
          },
          ...{ location_coords: o['location_coords'] }
        }
      })
      stateHash['homeMarkers'] = homeMarkers
    }
    if (props.growingSeasons.list!==prevProps.growingSeasons.list) {
      const growingSeasonsCopy = props.growingSeasons.list.slice().sort((a,b) => {
        const dateAInt = DateTime.fromISO(a.sowing_date).toMillis()
        const dateBInt = DateTime.fromISO(b.sowing_date).toMillis()
        return (dateAInt-dateBInt)
      })
      .filter(({harvestDate}) => (typeof harvestDate==='undefined'))
      const activeGrowingSeason = growingSeasonsCopy[0]
      if (activeGrowingSeason) {
        stateHash['activeGrowingSeason'] = {
          name: activeGrowingSeason.name,
          sowingDate: DateTime.fromISO(activeGrowingSeason.sowing_date),
          emergenceDate: DateTime.fromISO(activeGrowingSeason.emergence_date),
          floralInitiationDate: DateTime.fromISO(activeGrowingSeason.floral_initiation_date),
          remoteId: activeGrowingSeason.remote_id,
        }
      }
    }
    if (props.sitesSettings!==prevProps.sitesSettings) {
      stateHash['center'] =  props.sitesSettings['map_center']
      stateHash['attributes_select_list'] =  props.sitesSettings['map_attributes_select_list']
    }
    if (Object.keys(stateHash).length) {
      this.setState(stateHash,(state) => { console.log('state',state)})
    }
  }

  getDegreesPerPixel = (bounds,size) => {
    const xRight = bounds.ne.lng+180
    const xLeft = bounds.nw.lng+180
    const yTop = bounds.ne.lat+180
    const yBottom = bounds.se.lat+180
    const degreesWidth = xRight-xLeft
    const degreesHeight = yTop-yBottom
    return { x: degreesWidth/size.width, y: degreesHeight/size.height }
  }

  mapMarkerClickHandler = (id) => {
    const site = this.props.sites.sites.find((o) => o.id===id)
    ReactGA.event({
      category: 'Site Action',
      action: 'Map: Open Site Status Popup',
      label: site.name,
    })
    this.setState({
      showPopupId: id,
      showSiteNameHoverPopupId: null,
    })
  }

  mapMarkerMouseOverHandler = (id) => {
    this.setState({showSiteNameHoverPopupId: id})
  }

  mapMarkerMouseOutHandler = (id) => {
    this.setState({showSiteNameHoverPopupId: null})
  }

  closeStatusPopupHandler = () => {
    this.setState({ showPopupId: null })
  }

  mapTypeIdChangHandler = (event) => {
    const value = event.target.value
    ReactGA.event({
      category: 'Site Action',
      action: 'Map Options: Set Map Type',
      label: value.toString(),
    })
    this.setState({ mapTypeId: value })
    localStorage.setItem('mapTypeId',value)
  }

  mapShowAllAwsNamesChangHandler = (event) => {
    const value = event.target.checked
    ReactGA.event({
      category: 'Site Action',
      action: 'Map Options: Set Show AWS Names',
      label: value.toString(),
    })
    this.setState({ mapShowAllAwsNames: value })
    localStorage.setItem('mapShowAllAwsNames',value)
  }

  closeOptionsPanelHandler = () => {
    this.setState({ optionsPanelOpen: false })
  }

  setDisclaimerHiddenTrue = () => {
    localStorage.setItem('mapViewDisclaimerHidden',true)
    this.setState({ disclaimerHidden: true })
  }

  userSettingsButtonClickHandler = () => {
    this.setState({ userSettingsMenuVisible: !this.state.userSettingsMenuVisible })
  }

  userSettingsOverlayClickHandler = (event) => {
    if (event.target === event.currentTarget) {
      this.setState({ userSettingsMenuVisible: false })
    }
  }

  userSettingsMenuSetCentreZoomLevelClickHandler = () => {
    this.setState({
      setMapCenterZoomLevelDialogVisible: true,
      userSettingsMenuVisible: false,
    })
  }

  userSettingsMenuSetHomeLocationClickHandler = () => {

    const origHomeMarkers = this.state.homeMarkers.map((o) => { return { ...o, ...o['location_coords'] } })
    this.setState({
      origHomeMarkers: origHomeMarkers,
      setHomeLocationDialogVisible: true,
      userSettingsMenuVisible: false,
    })

  }

  setMapCenterZoomLevelDialogSaveButtonClickHandler = () => {
    localStorage.setItem('savedMapZoom',this.state.zoom)
    localStorage.setItem('savedMapCenter',JSON.stringify(this.state.center))
    this.setState({ setMapCenterZoomLevelDialogVisible: false })
  }

  setMapCenterZoomLevelDialogCancelButtonClickHandler = () => {
    this.setState({ setMapCenterZoomLevelDialogVisible: false })
  }

  setHomeLocationDialogSaveButtonClickHandler = () => {
    this.setState({ setHomeLocationDialogVisible: false })
    const homeMarker = this.state.homeMarkers[0]
    const updateParams = {
      name: homeMarker['name'],
      location_coords: homeMarker['location_coords'],
    }
    this.props.dispatch(updateGrowingLocation(homeMarker['remoteId'],updateParams))
  }

  setHomeLocationDialogCancelButtonClickHandler = () => {
    const homeMarkers = this.state.origHomeMarkers.map((o) => { return { ...o, ...o['location_coords'] } })
    this.setState({
      homeMarkers: homeMarkers,
      setHomeLocationDialogVisible: false
    })
  }

  userSettingsMenuSetGrowingSeasonDetailsClickHandler = () => {
    const origActiveGrowingSeason = { ...this.state.activeGrowingSeason }
    this.setState({
      growingSeasonSettingsModalVisible: true,
      userSettingsMenuVisible: false,
      origActiveGrowingSeason: origActiveGrowingSeason,
    })
  }

  growingSeasonDetailsSubmitHandler = () => {
    const { activeGrowingSeason } = this.state
    const updateParams = {}
    updateParams['name'] = activeGrowingSeason['name']
    const dateKeysArraysArray = [
      ['sowingDate','sowing_date'],
      ['emergenceDate','emergence_date'],
      ['floralInitiationDate','floral_initiation_date'],
      ['harvestDate','harvest_date']
    ]
    dateKeysArraysArray.forEach((dateKeysArray) => {
      if (activeGrowingSeason[dateKeysArray[0]]) {
        updateParams[dateKeysArray[1]] = activeGrowingSeason[dateKeysArray[0]].toISO()
      }
    })
    const remoteId = activeGrowingSeason['remoteId'] ? activeGrowingSeason['remoteId'] : uuidv4()
    this.props.dispatch(updateGrowingSeason(remoteId,updateParams))
    activeGrowingSeason['remoteId'] = remoteId
    this.setState({
      activeGrowingSeason: activeGrowingSeason,
      growingSeasonSettingsModalVisible: false,
    })
  }

  growingSeasonDetailsCancelHandler = () => {
    this.setState({
      activeGrowingSeason: { ...this.state.origActiveGrowingSeason },
      growingSeasonSettingsModalVisible: false
    })
  }

  growingSeasonNameChangeHandler = event => {
    const { activeGrowingSeason } = this.state
    activeGrowingSeason['name'] = event.target.value
    this.setState({ activeGrowingSeason: activeGrowingSeason })
  }

  growingSeasonDateChangeHandler = (fieldName,dateVal) => {
    const { activeGrowingSeason } = this.state
    activeGrowingSeason[fieldName] = dateVal.set({hour: 0, minute: 0})
    this.setState({ activeGrowingSeason: activeGrowingSeason })
  }

  getGrowingSeasonSettingsModal = (props,state) => {
    return <GrowingSeasonSettingsPanel
      growingSeasonName={state.activeGrowingSeason['name']}
      sowingDate={state.activeGrowingSeason['sowingDate']}
      emergenceDate={state.activeGrowingSeason['emergenceDate']}
      floralInitiationDate={state.activeGrowingSeason['floralInitiationDate']}
      harvestDate={state.activeGrowingSeason['harvestDate']}
      nameChangeHandler={this.growingSeasonNameChangeHandler}
      dateChangeHandler={this.growingSeasonDateChangeHandler}
      submitHandler={this.growingSeasonDetailsSubmitHandler}
      cancelHandler={this.growingSeasonDetailsCancelHandler}
    />
  }

  getUnitsHash = (appSettingsList) => {
    const unitsHash = {}
    appSettingsList.filter(({category}) => category==="unit_display").forEach(({key,value}) => unitsHash[key] = value)
    return unitsHash
  }

  routeToDataView = (siteSlug,dataViewSlug) => {
    this.props.dispatch(push(`/map/${siteSlug}/${dataViewSlug}`))
  }

  routeToSiteDataViewsList = (siteSlug) => {
    this.props.dispatch(push(`/sites/${siteSlug}`))
  }

  dataViewCloseDataViewHandler = () => {
    if (window.lastLocation && (window.lastLocation.host===window.location.host)) {
      this.props.history.goBack()
    } else {
      this.props.history.push(`/`)
    }
    this.props.dispatch(clearDataViewHash())
  }

  getMobileStatusPopup = (props,state) => {

    if (!state.showPopupId) return null

    const site = props.sites.sites.find(({id}) => id===state.showPopupId)
    const { id, name, slug, location, current_data_hash, current_data_last_updated,
      timezone_name, aws_type } = site

    const lastUpdatedMs = Date.parse(current_data_last_updated)

    return <MobileStatusPopup
      name={name}
      awsType={aws_type}
      slug={slug}
      siteSummaryDataHash={current_data_hash}
      lastUpdated={lastUpdatedMs}
      timezone={timezone_name}
      closeStatusPopupHandler={this.closeStatusPopupHandler.bind(this)}
      routeToDataViewHandler={this.routeToDataView}
    />

  }

  getOptionsPanel = (props,state) => {
    return <OptionsPanel
      mapTypeIdChangHandler={this.mapTypeIdChangHandler}
      mapTypeId={this.state.mapTypeId}
      mapShowAllAwsNamesChangHandler={this.mapShowAllAwsNamesChangHandler}
      mapShowAllAwsNames={this.state.mapShowAllAwsNames}
      closeOptionsPanelHandler={this.closeOptionsPanelHandler}
    />
  }

  optionsButtonClickHandler = () => {
    ReactGA.event({
      category: 'Site Action',
      action: 'Map: Open Options Panel',
    })
    this.setState({optionsPanelOpen: true})
  }

  getMetricDisplayStatuses = (metricToDisplay) => {
    let filterKey
    if (['current_day_rainfall','previous_day_rainfall'].includes(metricToDisplay)) {
      filterKey = 'daily_rainfall'
      return this.props.metricDisplayStatuses.list.filter(({metric_key, threshold_value}) => ((metric_key===filterKey) && (threshold_value > 0))).sort((a,b) => b.threshold_value-a.threshold_value)
    } else if (['wind_speed_2m','wind_speed_10m'].includes(metricToDisplay)) {
      filterKey = 'wind_speed'
      return this.props.metricDisplayStatuses.list.filter(({metric_key, threshold_value}) => (metric_key===filterKey)).sort((a,b) => b.threshold_value-a.threshold_value)
    } else {
      filterKey = metricToDisplay
      return this.props.metricDisplayStatuses.list.filter(({metric_key}) => metric_key===filterKey).sort((a,b) => b.threshold_value-a.threshold_value)
    }
  }

  onHomeLocationMouseClickMove = (childKey, childProps, mouse) => {

    console.log("XXX childProps",childProps)

    if (this.state.setHomeLocationDialogVisible!==true) return
    if (childProps['draggableHomeMarker']!==true) return

    const homeMarkers = this.state.homeMarkers.map((o) => { return { ...o, ...o['location_coords'] } })
    const newHomeMarker = homeMarkers.find(({remoteId}) => remoteId===childProps.remoteId)
    newHomeMarker.location_coords['lat'] = mouse.lat
    newHomeMarker.location_coords['lng'] = mouse.lng

    this.setState({
      draggable: false,
      homeMarkers: homeMarkers,
    })

  }

  onHomeLocationMouseUp = (childKey, childProps, mouse) => {
    this.setState({draggable: true})
  }

  renderBody = (props,state) => <SiteContainer noPadding>
    <HelmetTitle title={'Map'} />
    <div style={{ height: 'calc(100vh - 50px)', width: '100%', display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'row', flex: 1, position: 'relative' }}>
        <div style={{flex: 1, position: 'relative'}}>
          <MetricSelect
            options={state.attributes_select_list}
            value={this.state['metric_to_display']}
            onChange={this.metricSelectChangeHandler}
          />
          <OptionsButton clickHandler={this.optionsButtonClickHandler} />
          {props.apiAuth.isSignedIn && <UserLocationSettingsButton clickHandler={this.userSettingsButtonClickHandler}>
            <MapSettings />
          </UserLocationSettingsButton>}
          {props.apiAuth.isSignedIn &&
            <UserLocationSettingsMenuContainer
              visible={this.state.userSettingsMenuVisible}
              overlayClickHandler={this.userSettingsOverlayClickHandler}
            >
              <UserLocationSettingsMenuItem
                text={'Save Menu Centre & Zoom Level'}
                clickHandler={this.userSettingsMenuSetCentreZoomLevelClickHandler}
              />
              <UserLocationSettingsMenuItem
                text={'Set Home Location'}
                clickHandler={this.userSettingsMenuSetHomeLocationClickHandler}
              />
              <UserLocationSettingsMenuItem
                text={'Set Growing Season Details'}
                clickHandler={this.userSettingsMenuSetGrowingSeasonDetailsClickHandler}
              />
            </UserLocationSettingsMenuContainer>
          }
          <UserLocationSettingsMenuConfirmationBoxContainer
            visible={this.state.setMapCenterZoomLevelDialogVisible}
            content={['Move and zoom the map until it is at the position and zoom level you want to save, then click save.']}
            cancelBtnClickHandler={this.setMapCenterZoomLevelDialogCancelButtonClickHandler}
            saveButtonClickHandler={this.setMapCenterZoomLevelDialogSaveButtonClickHandler}
          />
          <UserLocationSettingsMenuConfirmationBoxContainer
            visible={this.state.setHomeLocationDialogVisible}
            content={['Drag the star to your home location, then click save.']}
            cancelBtnClickHandler={this.setHomeLocationDialogCancelButtonClickHandler}
            saveButtonClickHandler={this.setHomeLocationDialogSaveButtonClickHandler}
          />
          { state.optionsPanelOpen && this.getOptionsPanel(props,state) }
          { state.growingSeasonSettingsModalVisible && this.getGrowingSeasonSettingsModal(props,state) }
          <Disclaimer
            disclaimerHidden={this.state.disclaimerHidden}
            closeIconHandler={this.setDisclaimerHiddenTrue.bind(this)}
            textObject={props.appSettings.appSettings.find(({category, key}) => ( category==='text_label' && key==='map_view_disclaimer' ))}
          />
          <Legend
            metricKey={state['metric_to_display']}
            metricDisplayStatuses={this.getMetricDisplayStatuses(state['metric_to_display'])}
            unitsHash={this.getUnitsHash(props.appSettings.appSettings)}
          />
          { state.showPopupId && this.getMobileStatusPopup(props,state) }
          <GoogleMapReact
            bootstrapURLKeys={{ key: Environment.GOOGLE_MAP_API_KEY }}
            center={this.state.center}
            zoom={this.state.zoom}
            draggable={this.state.draggable}
            options={{
              fullscreenControl: true,
              zoomControl: !isMobile,
              mapTypeId: this.state.mapTypeId,
              gestureHandling: "greedy",
            }}
            onChange={(newVars) => {
              this.setState({
                mapVars: newVars,
                center: newVars.center,
                zoom: newVars.zoom,
              })
              localStorage.setItem('mapCenter',JSON.stringify(newVars.center))
              localStorage.setItem('mapZoom',JSON.stringify(newVars.zoom))
            }}
            onChildMouseDown={this.onHomeLocationMouseClickMove}
            onChildMouseUp={this.onHomeLocationMouseUp}
            onChildMouseMove={this.onHomeLocationMouseClickMove}
          >
            {this.getMapMarkers(props,state)}
          </GoogleMapReact>
        </div>
      </div>
    </div>
    { props.match.params.data_view_slug && props.dataView && this.renderShowDataView(props,state) }
  </SiteContainer>

  render = () => {
    return this.renderBody(this.props,this.state)
  }
}

export default connect(
  (store, props) => {
    const awsNetwork = store.awsNetworks.awsNetwork || {}
    const awsNetworkCentralLocation = awsNetwork['central_location']
    const awsNetworkMapZoomLevel = awsNetwork['map_zoom_level']
    const site = store.sites.sites.find(({slug}) => slug===props.match.params.site_slug)
    const sitesSettings = (store.sites && store.sites.settings) ? store.sites.settings : []
    const dataViews = site && site.data_views && site.data_views.filter(({data_view_type_category}) => data_view_type_category!=="summary")
    const dataView = dataViews && dataViews.find(({slug}) => slug===props.match.params.data_view_slug)
    const dataViewHash = store.dataViews.dataViewHash
    const windDirectionSeries = dataViewHash && dataViewHash.series && dataViewHash.series.slice().filter(({id}) => id.match(/^wind_direction/))
    const sitesFetching = store.sites.fetching
    const dataViewsFetching = store.dataViews.fetching
    const dataViewHashFetching = store.dataViews.dataViewHashFetching
    const dataViewHashFetched = store.dataViews.dataViewHashFetched
    const growingLocations = store.growingLocations
    const growingSeasons = store.growingSeasons
    const apiAuth = store.apiAuth
    const isSignedIn = store.apiAuth.isSignedIn
    return ({
      awsNetwork,
      awsNetworkCentralLocation,
      awsNetworkMapZoomLevel,
      apiAuth: apiAuth,
      isSignedIn: isSignedIn,
      sites: store.sites,
      site: site,
      sitesSettings: sitesSettings,
      sitesFetching: sitesFetching,
      dataViews: dataViews,
      dataView: dataView,
      dataViewHash: dataViewHash,
      dataViewsFetching: dataViewsFetching,
      dataViewHashFetching: dataViewHashFetching,
      dataViewHashFetched: dataViewHashFetched,
      windDirectionSeries: windDirectionSeries,
      appSettings: store.appSettings,
      metricDisplayStatuses: store.metricDisplayStatuses,
      growingSeasons: growingSeasons,
      growingLocations: growingLocations,
    })
  },
)(Map)
