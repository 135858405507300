export default {
  colors: {
    background_grey: '#F9F9F9',
    footer_text: '#9B9B9B',
    container_header_background: '#4A90E2',
    action_link: '#017E9C',
    data_view_icon_grey: '#A0A0A0',
    grid_row_divider: '#ADC0BE',
    opaque_screen: 'rgba(0, 0, 0, 0.2)',
    loading_spinner_color_grey: '#C0C0C0',
    border_grey: '#C0C0C0',
    button_background: '#4A90E2',
  },
  sizes: {
    h1: '28px',
    h2: '28px',
    h3: '24px',
    paragraph: '16px',
    footer_link: '16px',
  },
  fonts: {
    heading: "'Helvetica Neue', Helvetica, Arial, sans-serif",
    button: "'Helvetica Neue', Helvetica, Arial, sans-serif",
    subheading: "'Helvetica Neue', Helvetica, Arial, sans-serif",
    paragraph: "'Helvetica Neue', Helvetica, Arial, sans-serif",
    menu_item: "'Helvetica Neue', Helvetica, Arial, sans-serif",
  },
  breakpoints: {
    sm: 500,
    md: 768,
    lg: 1100,
  },
}
