import React from 'react'
import ReactGA from 'react-ga'
import { formatDateUpdated, formatValueDisplay, convertDegreesToIconAngle } from '../../utils/dateAndNumberFormatUtils'

import { ModalScreenOverlay, MobileStatusContainer, MobileStatusPopupHeaderContainer, MobileStatusPopupCloseIcon,
  MobileStatusPopupHeaderH1Row, MobileStatusPopupHeaderH1Cell, MobileStatusPopupH1, MobileStatusPopupHeaderDashboardLinkCell,
  MobileStatusPopupHeaderDashboardLink, MobileStatusPopupDateUpdated, MobileStatusPopupBodyContainer,
  MobileStatusPopupMetricCell, MobileStatusPopupMetricCellInner, MobileStatusPopupMetricLabel, MobileStatusPopupMetricUnit,
  MobileStatusPopupMetricValue, MobileStatusPopupWindContainer, MobileStatusPopupWindIcon, MobileStatusPopupWindLabel,
  MobileStatusPopupMetricIndicatorBoxRow, MobileStatusPopupMetricIndicatorBox, MobileStatusPopupAdContainer, MobileStatusPopupLink,
  MobileStatusPopupAd, MobileStatusPopupAdHeaderSponsoredMsg, MobileStatusPopupAdHeadlineTxt, MobileStatusPopupAdBodyTxt,
  MobileStatusPopupAdReadMore } from './styled-elements'

const WindIconAndDirectionLabel  = ({windDirectionValue,windDirectionLabel}) => {
  return <MobileStatusPopupWindContainer>
    <MobileStatusPopupWindIcon angle={convertDegreesToIconAngle(windDirectionValue)}/>
    <MobileStatusPopupWindLabel>
      {windDirectionLabel}
    </MobileStatusPopupWindLabel>
  </MobileStatusPopupWindContainer>
}

export default class MobileStatusPopup extends React.Component {

  constructor(props) {
    super(props)
    this.Overlay = React.createRef()
    this.PopupContainer = React.createRef()
  }

  getBoundingClientRect = () => {
    return this.PopupContainer.current.getBoundingClientRect()
  }

  componentDidUpdate(prevProps) {
  }

  callCloseActionIfNotMainContainer = (event,closeStatusPopupHandler) => {
    if (event.target === event.currentTarget) closeStatusPopupHandler.call()
  }

  renderBody = (props,state) => {

    const { name, slug, awsType, closeStatusPopupHandler, routeToDataViewHandler,
      lastUpdated, timezone, siteSummaryDataHash } = props

    const dataIsFresh = ((new Date().getTime()-lastUpdated) < 3600000) ?
      true :
      false

    return <ModalScreenOverlay ref={this.Overlay} onClick={e => this.callCloseActionIfNotMainContainer(e,closeStatusPopupHandler)}>
      <MobileStatusContainer ref={this.PopupContainer}>
        <MobileStatusPopupHeaderContainer>
          <MobileStatusPopupCloseIcon
            onClick={closeStatusPopupHandler}
          />
          <MobileStatusPopupHeaderH1Row>
            <MobileStatusPopupHeaderH1Cell>
              <MobileStatusPopupH1>
                {name}
              </MobileStatusPopupH1>
            </MobileStatusPopupHeaderH1Cell>
          </MobileStatusPopupHeaderH1Row>
          <MobileStatusPopupDateUpdated>
            {formatDateUpdated(lastUpdated,timezone)}
          </MobileStatusPopupDateUpdated>
        </MobileStatusPopupHeaderContainer>
        {(1===2) &&
          <MobileStatusPopupAdContainer top>
            <MobileStatusPopupLink href="http://taindata.com" target="_blank">
              <MobileStatusPopupAd top>
                <MobileStatusPopupAdHeaderSponsoredMsg>Sponsored message</MobileStatusPopupAdHeaderSponsoredMsg>
                <MobileStatusPopupAdHeadlineTxt>Soil moisture monitoring, designed for the needs of South Australian growers</MobileStatusPopupAdHeadlineTxt>
                <MobileStatusPopupAdBodyTxt>The TainData Tierra soil moisture data logger has been developed in partnership with leading consultants and growers in South Australia.</MobileStatusPopupAdBodyTxt>
                <MobileStatusPopupAdReadMore>Learn more...</MobileStatusPopupAdReadMore>
              </MobileStatusPopupAd>
            </MobileStatusPopupLink>
          </MobileStatusPopupAdContainer>
        }
        <MobileStatusPopupBodyContainer>
          {siteSummaryDataHash && siteSummaryDataHash.data_array && siteSummaryDataHash.data_array.sort((o) => o.sort_order ).map(({label, value, unit, value_str, wind_direction_value, wind_direction_label, styles},index) => {
            if ((awsType==='bom') && (value_str==='B')) return undefined
            if ((awsType==='hilltop') && (value_str==='H')) return undefined
            return <MobileStatusPopupMetricCell key={index}>
              <MobileStatusPopupMetricCellInner>
                <MobileStatusPopupMetricLabel>{ label }</MobileStatusPopupMetricLabel>
                <MobileStatusPopupMetricUnit>{ unit }</MobileStatusPopupMetricUnit>
                <MobileStatusPopupMetricValue>
                  { dataIsFresh ? value : "–" }
                  { dataIsFresh && Number.isInteger(value) && Number.isInteger(wind_direction_value) && <WindIconAndDirectionLabel windDirectionValue={wind_direction_value} windDirectionLabel={wind_direction_label} /> }
                </MobileStatusPopupMetricValue>
                <MobileStatusPopupMetricIndicatorBoxRow><MobileStatusPopupMetricIndicatorBox backgroundColor={dataIsFresh ? styles.background_color : "#FFF"}/></MobileStatusPopupMetricIndicatorBoxRow>
              </MobileStatusPopupMetricCellInner>
            </MobileStatusPopupMetricCell>
          })}
        </MobileStatusPopupBodyContainer>
        {(1===2) &&
        <MobileStatusPopupAdContainer bottom>
          <MobileStatusPopupLink href="http://taindata.com" target="_blank">
            <MobileStatusPopupAd bottom>
              <MobileStatusPopupAdHeaderSponsoredMsg>Sponsored message</MobileStatusPopupAdHeaderSponsoredMsg>
              <MobileStatusPopupAdHeadlineTxt>Soil moisture monitoring, designed for the needs of South Australian growers</MobileStatusPopupAdHeadlineTxt>
              <MobileStatusPopupAdBodyTxt>The TainData Tierra soil moisture data logger has been developed in partnership with leading consultants and growers in South Australia.</MobileStatusPopupAdBodyTxt>
              <MobileStatusPopupAdReadMore>Learn more...</MobileStatusPopupAdReadMore>
            </MobileStatusPopupAd>
          </MobileStatusPopupLink>
          </MobileStatusPopupAdContainer>
        }
      </MobileStatusContainer>
    </ModalScreenOverlay>

  }

  render() {
    return this.renderBody(this.props,this.state)
  }
}
