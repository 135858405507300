try {
  JSON.parse(localStorage.getItem('siteLocationsHash'))
} catch (e) {
  localStorage.removeItem('siteLocationsHash')
}

const initialState = {
  site: {},
  siteSummary: {},
  sites: [],
  siteLocationsHash: JSON.parse(localStorage.getItem('siteLocationsHash')) || {},
  settings: {},
  fetching: false,
  fetched: false,
  errors: null,
  saved: false,
  saving: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "SITES_FETCH_PENDING":
    case "CHILD_COMPANY_SITES_FETCH_PENDING":
      return {
        ...state,
        fetching: true,
        fetched: false,
        errors: null,
      }
    case "SITES_FETCH_REJECTED":
    case "CHILD_COMPANY_SITES_FETCH_REJECTED":
      return {
        ...state,
        fetching: false,
        errors: action.payload.response ? action.payload.response.data.errors : action.payload.message,
      }
    case "SITES_FETCH_FULFILLED":
    case "CHILD_COMPANY_SITES_FETCH_FULFILLED":
      let siteLocationsHash = state.siteLocationsHash
      const siteLocationsDifferent = Object.keys(siteLocationsHash).length !== action.payload.data.length
      const newSiteLocationsHash = {}
      let newSiteLocationsHashPresent = false
      if (siteLocationsDifferent) {
        newSiteLocationsHashPresent = true
        action.payload.data.forEach((site) => {
          newSiteLocationsHash[site.id] = site.location
        })
        localStorage.setItem('siteLocationsHash',JSON.stringify(newSiteLocationsHash))
        siteLocationsHash = newSiteLocationsHash
      }
      const sites = action.payload.data.slice()
      sites.forEach((site) => {
        site.location = siteLocationsHash[site.id]
      })
      return {
        ...state,
        fetching: false,
        fetched: true,
        sites: sites,
        errors: null,
      }
    case "SITE_FETCH_PENDING":
      return {
        ...state,
        fetching: true,
        fetched: false,
        site: null
      }
    case "SITE_FETCH_FULFILLED":
      return {
        ...state,
        fetching: false,
        fetched: true,
        site: action.payload.data
      }
    case "SITE_SETTINGS_FETCH_FULFILLED":
      return {
        ...state,
        settings: action.payload.data,
      }
    case  'USER_SIGN_OUT_PENDING':
      return {
        ...initialState
      }
    case 'USER_SIGN_OUT_FULFILLED':
      return {
        ...initialState
      }
    default:
      return state
  }
}


