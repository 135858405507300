import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'
import styled from 'styled-components'
import styledTheme from '../../styles/theme'
import WindowCloseSquare from '../icons/WindowCloseSquare'
import { closeMenu } from '../../actions/MenuActions'

const MenuScreenOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 15;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({show}) => show ? 'flex' : 'none'};
  justify-content: flex-end;
  align-items: center;
`
const MenuCloseIconContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  svg {
    height: 28px;
    width: 28px;
    fill: #666;
  }
`
const MenuDiv = styled.div`
  display: ${({show}) => show ? 'inline-flex' : 'none'};
  width: 300px;
  height: 100%;
  background-color: white;
  border-left: 1px solid black;
  box-shadow: -6px 0px 6px 1px #444;
`
const MenuDivInner = styled.div`
  margin-top: 40px;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
  font-size: 14px;
`
const MenuTopLevelLink = styled.div`
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  display: inline-flex;
  width: 100%;
  margin: 10px 0;
  a {
    text-decoration: none;
    width: 100%;
  }
`
const MenuH2 = styled.h2`
  display: inline-flex;
  width: 100%;
  margin: 10px 0;
`
const SubMenuLink = styled.div`
  font-size: 16px;
  font-weight: 500;
  display: inline-flex;
  width: 100%;
  margin: 8px 0;
  a {
    text-decoration: none;
    width: 100%;
    padding-left: 8px;
  }
`

class Menu extends React.Component {

  showSettings (event) {
    event.preventDefault()
  }

  closeMenuButtonHandler = () => {
    this.props.dispatch(closeMenu())
    ReactGA.event({
      category: 'Site Action',
      action: 'Menu Action',
      label: 'Closed - From Menu Button'
    })
  }

  menuScreenOverlayOnClick = () => {
    ReactGA.event({
      category: 'Site Action',
      action: 'Menu Action',
      label: 'Closed - From Outside Click'
    })
    this.props.dispatch(closeMenu())
  }

  menuDivOnClick = (e) => {
    e.stopPropagation()
  }

  linkClickHandler = () => {
    this.props.dispatch(closeMenu())
  }

  componentDidUpdate = (oldProps,oldState) => {
  }

  render () {
    const { menuOpen, apiAuth } = this.props
    const { isSignedIn } = apiAuth
    let keyVal = 0
    return (
      <MenuScreenOverlay show={menuOpen} onClick={this.menuScreenOverlayOnClick.bind(this)}>
        <MenuDiv show={menuOpen} onClick={this.menuDivOnClick.bind(this)}>
        <MenuCloseIconContainer onClick={this.closeMenuButtonHandler.bind(this)}><WindowCloseSquare /></MenuCloseIconContainer>
          <MenuDivInner>
            <MenuTopLevelLink key={`${++keyVal}`}><Link onClick={this.linkClickHandler.bind(this)} to={'/'}>Map</Link></MenuTopLevelLink>
          </MenuDivInner>
        </MenuDiv>
      </MenuScreenOverlay>
    )
  }
}

export default connect(
  (store, props) => {
    return ({
      apiAuth: store.apiAuth,
      menuOpen: store.menu.open,
      router: store.router,
    })
  },
)(Menu)
