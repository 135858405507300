import styled from 'styled-components'
import closeIcon from '../../assets/icons/svg/baseline-close-24px.svg'

export const DisclaimerContainer = styled.div`
  position: fixed;
  z-index: 20;
  bottom: 10px;
  left: 10px;
  background-color: #FFF;
  border: 1px solid black;
  max-width: 300px;
  padding: 18px;
  font-size: 12px;
`

export const DisclaimerCloseIcon = styled.div`
  position: absolute;
  height: 18px;
  width: 18px;
  right: 4px;
  top: 4px;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background-image: url(${closeIcon});
  background-size: cover;
  cursor: pointer;
`
export const ModalAndMenuScreenOverlay = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 0px;
  z-index: 50;
`

export const UserLocationSettingsButtonContainer = styled.div`
  position: absolute;
  z-index: 10;
  bottom: 40px;
  left: 10px;
`

export const UserLocationSettingsButtonInner = styled.div`
  height: 60px;
  width: 60px;
  border-width: 3px;
  border-style: solid;
  border-color: #666;
  border-radius: 20px;
  background-color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 40px;
    width: 40px;
    g {
      g {
        fill: #666;
      }
    }
  }
`
